import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CircularProgress, IconButton, Menu, MenuItem } from "@mui/material";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import AlertDialog from "../../../common/AlertDialog/AlertDialog";
import { download } from "../../../store/LCA/lcaSlice";
import { showMessage } from "../../../store/Revalu/notificationSlice";
import downloadAllImages, {
  downloadAllTypesDocuments,
} from "./downloadAllImages";

const ITEM_HEIGHT = 48;

function ActionButtons(props) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [downloadingTypesDocuments, setDownloadingTypesDocuments] =
    React.useState(false);
  const [alert, setAlert] = React.useState(false);
  const [materialId, setMaterialId] = React.useState("");
  const downloadRef = React.useRef(null);
  const open = Boolean(anchorEl);
  const {
    rowData,
    onViewDetails = () => {},
    handlerDelete = () => {},
    handleSelectedIdEdit = () => {},
    handleSelectIdUUID = () => {},
  } = props;

  const alertConfirmation = useSelector(
    ({ alert }) => alert.alertSlice.alertConfirm,
  );
  if (!rowData) {
    return false;
  }

  const { userId } = rowData;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isValidUrl = (val) => {
    return val.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
    );
  };

  const downloadFile = (fileUrl, type, isExternal) => {
    try {
      downloadRef.current.href = fileUrl;
      if (!isExternal) {
        downloadRef.current.download = `EPD ${type} - ${new Date().toLocaleString()}`;
      }
      downloadRef.current.click();
      if (!isExternal) {
        dispatch(showMessage({ message: "File downloaded successfully" }));
      }
    } catch (ex) {
      throw ex;
    }
  };

  const handleDownload = async (type) => {
    try {
      handleClose();
      setLoading(true);
      const res = await dispatch(
        download({
          id: rowData?.processInformation?.dataSetInformation?.UUID,
          type,
        }),
      );
      if (
        typeof res === "string" &&
        isValidUrl(res) &&
        downloadRef &&
        downloadRef.current
      ) {
        downloadFile(res, type);
      } else if (
        Number(res.status) === 307 &&
        res.data &&
        typeof res.data === "string" &&
        isValidUrl(res.data)
      ) {
        downloadFile(res.data, type, true);
      } else {
        throw new Error("An unknown error occurred!");
      }
    } catch (ex) {
      dispatch(showMessage({ message: ex.message || ex, variant: "error" }));
    } finally {
      setLoading(false);
    }
  };

  const handleViewDetails = () => {
    onViewDetails(userId);
    handleClose();
  };

  const showAlertDialogDelete = (id) => {
    setAlert(true);
    setMaterialId(id);
  };

  const deleteHandler = async () => {
    await handlerDelete(materialId);
    setAlert(false);
  };

  const handleDownloadAllTypesDocuments = async () => {
    try {
      setDownloadingTypesDocuments(true);
      await downloadAllTypesDocuments(
        rowData?.documentTypes,
        "documentTypeImages",
      );
      setDownloadingTypesDocuments(false);
    } catch (error) {
      console.error(error);
      setDownloadingTypesDocuments(false);
    }
  };

  return (
    <div>
      {loading ? (
        <CircularProgress size={20} color="primary" />
      ) : (
        <>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls="long-menu"
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            size="large">
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            style={{
              marginTop: 10,
              maxHeight: ITEM_HEIGHT * 7.5,
              width: "35ch",
            }}>
            {rowData?.certificationImages[0]?.certificationImage && (
              <MenuItem
                onClick={() =>
                  downloadAllImages(
                    rowData?.certificationImages,
                    "certificationImage",
                  )
                }>
                Download Certification
              </MenuItem>
            )}
            {rowData?.documentTypes[0]?.documentTypeImages[0]
              ?.documentTypeImage && (
              <MenuItem onClick={handleDownloadAllTypesDocuments}>
                {" "}
                Download Types Document
              </MenuItem>
            )}
            <MenuItem onClick={() => handleSelectedIdEdit(rowData?.id)}>
              Change Status
            </MenuItem>
            {rowData?.epdImages[0]?.epdImage && (
              <MenuItem
                onClick={() =>
                  downloadAllImages(rowData?.epdImages, "epdImage")
                }>
                Download EPD
              </MenuItem>
            )}
            {rowData?.materialImage[0]?.materialImage && (
              <MenuItem
                onClick={() =>
                  downloadAllImages(rowData?.materialImage, "materialImage")
                }>
                Download Material Image
              </MenuItem>
            )}
            {rowData?.technicalDataSheetImages[0]?.technicalDataSheetImage && (
              <MenuItem
                onClick={() =>
                  downloadAllImages(
                    rowData?.technicalDataSheetImages,
                    "technicalDataSheetImage",
                  )
                }>
                Download Data Sheet
              </MenuItem>
            )}

            {rowData?.additionalDocumentImages[0]?.additionalDocumentImage && (
              <MenuItem
                onClick={() =>
                  downloadAllImages(
                    rowData?.additionalDocumentImages,
                    "documentTypeImages",
                  )
                }>
                Download Additional Document
              </MenuItem>
            )}
            <MenuItem onClick={handleViewDetails}>View User Details</MenuItem>
            <MenuItem onClick={() => handleSelectIdUUID(rowData?.id)}>
              Add UUID
            </MenuItem>
            <MenuItem onClick={() => showAlertDialogDelete(rowData?.id)}>
              Delete
            </MenuItem>
          </Menu>
        </>
      )}
      <a
        className="d-none"
        ref={downloadRef}
        target="_blank"
        aria-hidden="true">
        Download
      </a>
      {alert === true ? (
        <AlertDialog
          title="Delete Custom Material"
          message="Are you sure want to delete this Custom Material?"
          onConfirm={deleteHandler}
          onClose={setAlert}
        />
      ) : (
        ""
      )}
    </div>
  );
}
export default ActionButtons;
