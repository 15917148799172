import { Box, CircularProgress, Dialog } from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { renderValueAttribute } from "../../../common/Helper/renderValue";
import {
  deletePost,
  getAllPosts,
  setSelected,
} from "../../../store/ProductNews/productNewsSlice";
import { showMessage } from "../../../store/Revalu/notificationSlice";
import { DialogHeader } from "../Common/dialogHeader";
import ActionButtons from "./actionButtons";

// redux

//Components
import AttributeDetails from "./attributeDetails";
import CreateProductNewsForm from "./createProductNewsForm";
import TableActionButton from "./tableActionButton";

const ProductNews = () => {
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [selectedIdToView, setSelectedIdToView] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [sortBy, setSortBy] = useState({});
  const [showAddModal, setShowAddModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const dispatch = useDispatch();
  const { data, loading } = useSelector(({ productNews }) => productNews.slice);

  const handleSelect = (id) => {
    dispatch(setSelected({ id }));
    setSelectedId(id);
    setShowAddModal(true);
  };
  const handleViewDetails = (id) => {
    setSelectedIdToView(id);
    setSelectedId(id);
  };
  const handleDelete = async (id) => {
    try {
      setSelectedId(id);
      const res = await dispatch(deletePost(id));
      if (res) {
        dispatch(showMessage({ message: "Record deleted" }));
        updateTable();
      } else {
        dispatch(
          showMessage({
            message: "An unknown error occurred",
            variant: "error",
          }),
        );
      }
    } catch (ex) {
      dispatch(
        showMessage({
          message: ex.message ? ex.messge : ex,
          variant: "error",
        }),
      );
    }
  };
  const handleCloseDetails = () => {
    setSelectedIdToView(null);
  };
  const updateTable = () => {
    dispatch(getAllPosts({ pageNo, pageSize, sortBy }));
  };
  useEffect(() => {
    updateTable();
  }, [pageNo, pageSize, sortBy]);

  useEffect(() => {
    if (selectedId) {
      setCurrentRecord(data?.content?.find((f) => f.id === selectedId));
    }
  }, [selectedId]);

  const handleSortBy = (e, direction) => {
    const fieldNames = {
      "Start date": "validFrom",
      "End date": "validUntil",
    };

    const directions = {
      asc: "ASC",
      desc: "DESC",
    };
    setSortBy({
      fieldName: fieldNames[e],
      direction: directions[direction],
    });
  };

  const headers = [
    {
      name: "Post Id",
      options: {
        sort: false,
        customHeadRender: (columnMeta) => (
          <th
            key={columnMeta.index}
            style={{
              width: "60px",
              fontSize: "0.875rem",
              fontWeight: "400",
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
            }}>
            {columnMeta.name}
          </th>
        ),
      },
    },
    {
      name: "Post Title",
      options: {
        sort: false,
        customHeadRender: (columnMeta) => (
          <th
            key={columnMeta.index}
            style={{
              width: "700px",
              fontWeight: "400",
              fontSize: "0.875rem",
              alignItems: "center",
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
            }}>
            {columnMeta.name}
          </th>
        ),
        customBodyRender: (value) => {
          return <span>{renderValueAttribute(value)}</span>;
        },
      },
    },
    {
      name: "Comments",
      options: {
        sort: false,
        customHeadRender: (columnMeta) => (
          <th
            key={columnMeta.index}
            style={{
              width: "200px",
              fontWeight: "400",
              fontSize: "0.875rem",
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
            }}>
            {columnMeta.name}
          </th>
        ),
        customBodyRender: (value) => {
          return <span>{renderValueAttribute(value)}</span>;
        },
      },
    },
    {
      name: "Start date",
      options: {
        sort: true,
      },
    },
    {
      name: "End date",
      options: {
        sort: true,
      },
    },
    {
      name: "#Reads (incl. int users)",
      options: {
        sort: false,
        customHeadRender: (columnMeta) => (
          <th
            key={columnMeta.index}
            style={{
              width: "100px",
              fontWeight: "400",
              fontSize: "0.875rem",
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
            }}>
            {columnMeta.name}
          </th>
        ),
      },
    },
    {
      name: "Actions",
      options: {
        sort: false,
        customHeadRender: (columnMeta) => (
          <th
            key={columnMeta.index}
            style={{
              width: "50px",
              fontWeight: "400",
              fontSize: "0.875rem",
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
            }}>
            {columnMeta.name}
          </th>
        ),
        customBodyRender: (value) => {
          return (
            <ActionButtons
              onEdit={handleSelect}
              onViewDetails={handleViewDetails}
              rowData={value}
              onDelete={handleDelete}
              setSelectedId={setSelectedId}
            />
          );
        },
      },
    },
  ];
  const hideModal = () => {
    setShowAddModal(false);
  };
  const getParsedData = () => {
    return data?.content?.map((m) => {
      return [
        m?.id,
        m.header,
        m.comment,
        new Date(m.validFrom).toLocaleString("en-US"),
        new Date(m.validUntil).toLocaleString("en-US"),
        m.readCount,
        m,
      ];
    });
  };

  const options = {
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    checkbox: false,
    selectableRowsHeader: false,
    selectableRows: "none",
    filterType: "checkbox",
    serverSide: true,
    rowsPerPageOptions: [10, 20, 50],
    onColumnSortChange: (col, direction) => handleSortBy(col, direction),
    onSearchClose: () => {
      dispatch(searchData({ search: "" }));
    },
    onTableChange: (action, state) => {
      const { page, rowsPerPage } = state;
      switch (action) {
        case "changePage":
          setPageNo(page);
          break;
        case "changeRowsPerPage":
          setPageSize(rowsPerPage);
          break;
      }
    },
  };

  return (
    <div>
      <div>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "20px",
            mb: 2,
          }}>
          <Box
            display="inline-flex"
            justifyContent="flex-end"
            gap="10px"
            flexWrap="wrap">
            <TableActionButton
              onClick={() => {
                setShowAddModal(true);
                setCurrentRecord(null);
              }}
              title="Add a new Post"
            />
          </Box>
        </Box>
      </div>
      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(0,0,0,0.3)",
            display: "flex",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            padding: "30px 20px",
            zIndex: 999,
            "& >div": {
              zIndex: 1000,
            },
          }}>
          <CircularProgress />
        </Box>
      )}

      <div>
        <div>
          <MUIDataTable
            title="Product News"
            columns={headers}
            data={getParsedData()}
            options={{ ...options, count: data?.totalElements }}
            onPageChange={() => {}}
          />
        </div>
      </div>
      {showAddModal && (
        <Dialog
          aria-labelledby="admin-user-form-dialog"
          onClose={hideModal}
          open={showAddModal}>
          <DialogHeader
            onClose={hideModal}
            title={currentRecord ? "Update" : "Create" + " Product News"}
          />
          <CreateProductNewsForm
            currentRecord={currentRecord}
            onClose={hideModal}
            updateTable={updateTable}
            setSelectedId={setSelectedId}
          />
        </Dialog>
      )}
      {Boolean(selectedIdToView) && (
        <Dialog
          open={Boolean(selectedIdToView)}
          maxWidth="lg"
          fullWidth
          onClose={handleCloseDetails}
          scroll="body">
          <DialogHeader
            onClose={handleCloseDetails}
            title={"Product News Details"}
          />
          <AttributeDetails data={currentRecord} />
        </Dialog>
      )}
    </div>
  );
};

export default ProductNews;
