import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable_New from "../../../common/DataTable/dataTable_new";
import {
  addAPIKey,
  deleteAPIKey,
  getAllAdminUser,
  resetSelected,
} from "../../../store/AdminUser/adminUserSlice";

import CloseIcon from "@mui/icons-material/Close";
import { Box, CircularProgress, Dialog, DialogTitle } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { USER_STATUS } from "../../../store/App/constants";
import { showMessage } from "../../../store/Revalu/notificationSlice";
import { DialogHeader } from "../Common/dialogHeader";
import AddAPIKey from "../EDPManagement/MappedEPDs/addAPIKey";
import CreateAdminUserForm from "../EDPManagement/MappedEPDs/createAdminUserForm";
import actionButtonsAdminUsers from "./actionButtonsAdminUsers";
import { ChangeUserTier } from "./changeUserTierDialog";

const AllAdminUsers = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showAddAPIKeyModal, setShowAddAPIKeyModal] = useState(false);
  const [viewUserTierDialog, setViewUserTierDialog] = useState(false);
  const dispatch = useDispatch();

  const viewAllAdminUsers = useSelector(
    ({ adminUser }) => adminUser.adminUserAll.data,
  );
  const { loading, selected: currentRecord } = useSelector(
    ({ adminUser }) => adminUser.adminUserAll,
  );

  const handleRowClick = (data) => {
    setSelectedRow(data);
  };
  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };
  const hideModal = () => {
    setShowModal(false);
    dispatch(resetSelected());
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = {};
      await dispatch(getAllAdminUser(data));
    };

    fetchData();
  }, [dispatch]);

  const showChangeUserTierModal = () => {
    setViewUserTierDialog(true);
  };

  // METHODS FOR ADD/DELETE API KEY
  const handleOpenAddAPIKeyModal = () => {
    setShowAddAPIKeyModal(true);
  };
  const handleCloseAddAPIKeyModal = () => {
    setShowAddAPIKeyModal(false);
  };
  const handleAddAPIKey = async ({ apiKey }) => {
    try {
      if (!selectedRow) {
        throw new Error("No user selected!");
      }
      const userId = selectedRow[0];
      if (!userId) {
        throw new Error("No user selected!");
      }
      const res = await dispatch(addAPIKey({ apiKey, userId }));
      const { status, data } = res;
      if (Number(status) === 200 && data) {
        dispatch(showMessage({ message: "API key added successfully" }));
        handleCloseAddAPIKeyModal();
      } else {
        dispatch(
          showMessage({
            message: "Error in assigning API key, please try again",
            variant: "error",
          }),
        );
      }
    } catch (err) {
      console.error(err);
      dispatch(
        showMessage({
          message: err.data["database exception"],
          variant: "error",
        }),
      );
    }
  };
  const handleDeleteAPIKey = async () => {
    try {
      if (!selectedRow) {
        throw new Error("No user selected!");
      }
      const userId = selectedRow[0];
      if (!userId) {
        throw new Error("No user selected!");
      }
      const res = await dispatch(deleteAPIKey({ userId }));
      const { status, data } = res;
      if (Number(status) === 200 && data) {
        dispatch(showMessage({ message: "API key deleted successfully" }));
      } else {
        dispatch(
          showMessage({
            message: "Error in deleting API key, please try again",
            variant: "error",
          }),
        );
      }
    } catch (err) {
      console.error(err);
      dispatch(
        showMessage({
          message: err.message,
          variant: "error",
        }),
      );
    }
  };
  // : METHODS FOR ADD/DELETE API KEY

  const headings = [
    "ID",
    "User ID",
    "First Name",
    "Last Name",
    "Email",
    "Status",
    "Active?",
    "API",
    "User Tier",
  ];

  const rowsData = viewAllAdminUsers.map((item) => {
    return [
      item.id,
      item.id,
      item.firstName,
      item.lastName,
      item.email,
      USER_STATUS[item.currentUserStatus] || "",
      item.isActive ? "Yes" : "No",
      item.isApiKeyExists !== null ? "Yes" : "", // index = 7
      item.user_tier,
      item.beta_user_group,
    ];
  });

  return (
    <>
      <div>
        {loading ? (
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: "rgba(0,0,0,0.3)",
              display: "flex",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              padding: "30px 20px",
              zIndex: 999,
              "& >div": {
                zIndex: 1000,
              },
            }}>
            <CircularProgress />
          </Box>
        ) : null}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between", // Ensures items are spaced at opposite ends
            alignItems: "center", // Align items vertically (optional)
            padding: "16px", // Optional padding
          }}>
          <Typography variant="h5">Admin Users</Typography>
          <Button
            style={{ marginLeft: 13 }}
            variant="contained"
            color="primary"
            onClick={toggleModal}
            startIcon={<AddCircleIcon style={{ color: "#c4cbda" }} />}>
            Add new
          </Button>
        </Box>
        <DataTable_New
          hideColumnsIndexes={[0, 9]}
          headings={headings}
          rowsData={rowsData}
          allowActionButtons
          onRowClick={handleRowClick}
          ActionButtons={actionButtonsAdminUsers}
          actionButtonsProps={{
            onAddAPIKeyClick: handleOpenAddAPIKeyModal,
            handleDeleteAPIKey: handleDeleteAPIKey,
            showChangeUserTierModal: showChangeUserTierModal,
          }}
        />
      </div>

      {/* DIALOG FOR ADD API KEY */}
      <Dialog
        open={showAddAPIKeyModal}
        maxWidth="md"
        fullWidth
        scroll="body"
        onClose={handleCloseAddAPIKeyModal}>
        <DialogHeader
          onClose={handleCloseAddAPIKeyModal}
          title={"Add API Key"}
        />
        <AddAPIKey onAddAPIKey={handleAddAPIKey} loading={loading} />
      </Dialog>
      {/* : DIALOG FOR ADD API KEY */}
      {viewUserTierDialog && (
        <ChangeUserTier
          open={viewUserTierDialog}
          setOpen={setViewUserTierDialog}
          selectedRow={selectedRow}
        />
      )}

      {(showModal || currentRecord) && (
        <Dialog
          aria-labelledby="admin-user-form-dialog"
          onClose={hideModal}
          open={showModal || currentRecord}>
          <DialogTitle>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between">
              <Box>
                {Boolean(currentRecord) ? "Update" : "Create"} Admin User
              </Box>
              <Box textAlign="right">
                <IconButton onClick={hideModal} size="large">
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <CreateAdminUserForm
            currentRecord={currentRecord}
            onClose={hideModal}
          />
        </Dialog>
      )}
    </>
  );
};

export default AllAdminUsers;
