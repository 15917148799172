import { Box, CircularProgress } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import WarningItem from "../../../../common/Helper/warningItem";
import { getErrorssById } from "../../../../store/QualityValidation/qualityValidationSlice";

const ViewErrors = (props) => {
  const dispatch = useDispatch();
  const { id } = props;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      (async () => {
        try {
          const res = await dispatch(getErrorssById(id));
          if (res && Boolean(res.dataSourceId)) {
            setData(res);
          }
        } catch (ex) {
          dispatch(
            showMessage({ message: ex.message || ex, variant: "error" }),
          );
        } finally {
          setLoading(false);
        }
      })();
    }
  }, []);
  return (
    <div>
      {loading ? (
        <Box textAlign="center">
          <CircularProgress color="primary" />
        </Box>
      ) : !data ? (
        <p>No erorrs</p>
      ) : (
        <Fragment>
          {data?.qualityCheckErrors &&
            data?.qualityCheckErrors.map((m) => (
              <WarningItem data={m} key={m.code} />
            ))}
        </Fragment>
      )}
    </div>
  );
};

export default ViewErrors;
