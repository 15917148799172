import { yupResolver } from "@hookform/resolvers/yup";
import { Button, DialogActions, Grid, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import {
  updateDocument,
  uploadDocument,
} from "../../../../store/MappedEDPs/mappedEPDSlice";

import { Autocomplete } from "@mui/material";
import { useState } from "react";
import { showMessage } from "../../../../store/Revalu/notificationSlice";
import { handleUploadFile } from "../../../../utils/fileUpload";

const PREFIX = "UploadDocument";

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  formWrapper: `${PREFIX}-formWrapper`,
  chooseFileLabel: `${PREFIX}-chooseFileLabel`,
  label: `${PREFIX}-label`,
  submitButton: `${PREFIX}-submitButton`,
  fileInput: `${PREFIX}-fileInput`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.wrapper}`]: {
    padding: 22,
    boxShadow: "0px 2px 5px #343D4F08",
    borderRadius: 12,
    "& .title": {
      fontSize: 17,
      fontWeight: 500,
      color: theme.palette.primary.fontColor,
    },
  },

  [`& .${classes.formWrapper}`]: {
    marginBottom: 13,
    "& .textfield": {
      border: "1px solid " + theme.palette.drawer.border,
      borderRadius: 13,
      background: theme.palette.drawer.textFieldBg,
      "& input": {
        padding: "7px 18px",
        height: 11,
      },
    },
  },

  [`& .${classes.chooseFileLabel}`]: {
    padding: "18.5px 14px",
    border: "1px solid " + theme.palette.drawer.border,
    display: "flex",
    borderRadius: 13,
    background: theme.palette.drawer.textFieldBg,
    whiteSpace: "nowrap",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },

  [`& .${classes.label}`]: {
    color: theme.palette.primary.fontColor,
    fontWeight: 500,
    fontSize: 16,
    display: "block",
    marginBottom: 5,
  },

  [`& .${classes.submitButton}`]: {
    marginLeft: "auto",
    display: "block",
    marginTop: 13,
    "& span": {
      padding: 0,
    },
  },

  [`& .${classes.fileInput}`]: {
    display: "none",
  },
}));

const UploadDocument = ({
  currentRecord,
  datasource,
  onClose = () => {},
  setReferenceProject = () => {},
  handleReferenceProject = () => {},
}) => {
  const dispatch = useDispatch();
  // const { authenticatedUser } = useSelector(({ user }) => user.userAccount)
  const [loading, setLoading] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState();

  const id = currentRecord?.id;
  const defaultValues = {
    adminUserName:
      currentRecord?.adminUserName || localStorage.getItem("email_id") || "",
    dataSourceId: currentRecord?.dataSourceId || datasource,
    documentUri: currentRecord?.documentUri || "",
    source: currentRecord?.source || "",
    type: currentRecord?.type || "",
    uploadedFileName: selectedFileName || "",
  };

  const addNewSchema = yup.object().shape({
    adminUserName: yup.string(),
    dataSourceId: yup.string(),
    documentUri: yup.string().required("Required"),
    source: yup.string(),
    type: yup.string(),
    selectedTypeOption: yup.string(),
    uploadedFileName: yup.string(),
  });

  const {
    control,
    formState,
    handleSubmit,
    getValues,
    setError,
    setValue,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(addNewSchema),
  });
  const { isValid, dirtyFields, errors } = formState;

  const TYPES = [
    "Declaration of Performance",
    "Product Data Sheet",
    "Safety Data Sheet",
    "European Technical Assessment",
    "Material Safety Datasheet",
    "BREEAM Helpsheet",
    "Construction Details",
    "Panel project Preparation",
    "Building Elements",
    "Assembly Details",
    "Screw Connections",
    "Electrical Installations",
    "Wufi Report",
    "Warranty",
    "Transport Cost Guide",
    "Transport & Storage Guide",
    "Order Guide",
    "EUrofin Certificate",
    "BBA Certificate",
    "PH Certified Details",
    "FSC Certification",
    "Kiwa Verification Certification",
    "PH Certification Report",
    "Passivhaus Certificate",
    "Cradle to Cradle Certificate",
    "NBS Specification",
    "Building Physics Guide",
    "Specification Guide",
    "Assembly Guide",
    "Installation Guide",
    "Product Brochure",
    "Sample Structural Calculations",
    "Earthquake Results",
    "Structural Design",
    "Reaction to Fire & Fire Resistance",
    "Fire Protection",
    "Classification of Fire Resistance",
    "Reference Project",
    "Life Cycle Assessment",
    "Certificate",
    "Other document",
    "Technical Data Sheet",
    "Toxicity Declaration",
    "Product Catalogue",
    "Care and Maintenance Sheet",
    "Climate declaration",
  ];

  TYPES.sort();

  // Add the EPD as the first element
  TYPES.unshift("Environmental Product Declaration");

  const onResponse = (res) => {
    if (Boolean(res)) {
      dispatch(
        showMessage({
          message: `The document has been ${id ? "updated" : "uploaded"}`,
        }),
      );
      reset();
      onClose();
    } else if (Number(status) === 400) {
      if (
        data &&
        data["database exception"]
          ?.toString()
          ?.toLowerCase()
          ?.indexOf("duplicate") > -1
      ) {
        dispatch(showMessage({ message: "already exists", variant: "error" }));
      }
    }
  };

  const onSubmit = async (model) => {
    setLoading(true);
    if (Boolean(id)) {
      const res = await dispatch(updateDocument(id, model));
      onResponse(res);
    } else {
      const res = await dispatch(uploadDocument(model));
      onResponse(res);
    }
    setLoading(false);
  };

  const handleUpload = (e, field) => {
    if (e.target.files.length) {
      setLoading(true);
      handleUploadFile(e)
        .then((res) => {
          setValue(field, res.data);
          setValue("uploadedFileName", e.target.files[0].name);
          setSelectedFileName(e.target.files[0].name);
        })
        .catch((err) => {
          alert(err?.message || "Error uploading file");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleTypes = (e, newValue) => {
    setValue("type", newValue);
    if (newValue === "Reference Project") {
      setReferenceProject(true);
      handleReferenceProject(datasource);
      onClose();
    } else {
      setReferenceProject(false);
      handleReferenceProject(null);
    }
  };
  const handlefileName = (e) => {
    setSelectedFileName(e.target.value);
    setValue("uploadedFileName", e.target.value);
  };
  return (
    <Root>
      <div className={classes.wrapper}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <div className={classes.formWrapper}>
                <label className={classes.label}>Document</label>
                <div
                  style={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "row",
                    gap: "20px",
                  }}>
                  <input
                    type={"file"}
                    id="file-input"
                    className={classes.fileInput}
                    onChange={(e) => handleUpload(e, "documentUri")}
                  />
                  <Grid item xs={3} md={3}>
                    <div className={classes.formWrapper}>
                      <label
                        id="file-input-label"
                        htmlFor="file-input"
                        className={classes.chooseFileLabel}>
                        Choose File
                      </label>
                    </div>
                  </Grid>
                  <Grid item xs={9} md={9}>
                    <div className={classes.formWrapper}>
                      <Controller
                        name="type"
                        control={control}
                        className="textfield"
                        render={({ field }) => (
                          <TextField
                            id="text-input-upload-document"
                            {...field}
                            error={!!errors.type}
                            helperText={errors?.type?.message}
                            variant="outlined"
                            type="text"
                            placeholder="Filename"
                            value={selectedFileName}
                            onChange={(e) => handlefileName(e)}
                          />
                        )}
                      />
                      {/* <input
                  type="text"
                  value={selectedFileName ? selectedFileName : ""}
                /> */}
                    </div>
                  </Grid>
                </div>
                {getValues("documentUri") && "The Document is uploaded"}
              </div>
            </Grid>
            <div className={classes.formWrapper}>
              <label className={classes.label}>Source</label>
              <Controller
                name="source"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <TextField
                    id="text-input-upload-document"
                    {...field}
                    error={!!errors.source}
                    helperText={errors?.source?.message}
                    variant="outlined"
                    placeholder="Source"
                    inputProps={{ maxLength: 240 }}
                  />
                )}
              />
            </div>
            <Grid item xs={12} md={12}>
              <div className={classes.formWrapper}>
                <label className={classes.label}>Type</label>
                <Autocomplete
                  onChange={handleTypes}
                  fullWidth
                  // disablePortal
                  id="fieldName"
                  options={TYPES}
                  renderInput={(params) => (
                    <TextField
                      id="text-input-upload-document"
                      variant="outlined"
                      fullWidth
                      required
                      {...params}
                      label="Document Type"
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={1} md={1}></Grid>
            <Grid item xs={12} md={12}>
              <div className={classes.formWrapper}>
                <label className={classes.label}>Selected Type</label>
                <Controller
                  name="type"
                  control={control}
                  className="textfield"
                  render={({ field }) => (
                    <TextField
                      id="text-input-upload-document"
                      {...field}
                      error={!!errors.type}
                      helperText={errors?.type?.message}
                      variant="outlined"
                      type="text"
                      placeholder="Type"
                      sx={{ width: "100%" }}
                    />
                  )}
                />
              </div>
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!Boolean(getValues("documentUri")) || loading}
              className={classes.submitButton}>
              {loading ? "Saving..." : "Save"}
            </Button>
          </DialogActions>
        </form>
      </div>
    </Root>
  );
};

export default UploadDocument;
