/* eslint-disable prettier/prettier */
import axios from "axios";

// Same for all environments
export const BASE_HOST = "dev.revalu.world";
export const BASE_HOST_PRODUCT = "revalu-dev-product.i-ways-network.org";
export const BASE_HOST_CONTENT = "revalu-dev-content.i-ways-network.org";

// ====================================================

export const BASE_URL = "https://" + BASE_HOST + "/";
export const BASE_URL_PRODUCT = "https://" + BASE_HOST_PRODUCT + "/";
export const BASE_URL_CONTENT = "https://" + BASE_HOST_CONTENT + "/";

// Platform specific exports


// DEV REVALU
const BASE_HOST_EPD_MANAGEMENT_DEV_REVALU = "epd-dev.revalu.world";
const BASE_HOST_V2_DEV_REVALU = "dev.revalu.world";
const BASE_HOST_ADMIN_USERS_DEV_REVALU =
  "https://user-management-dev.revalu.world";
const BASE_HOST_ANALYTICS_DEV = "analytics-service-dev.revalu.world";

// STAGE REVALU
const BASE_HOST_EPD_MANAGEMENT_STAGE_REVALU = "epd-stage.revalu.world";
const BASE_HOST_V2_STAGE_REVALU = "stage.revalu.world";
const BASE_HOST_ADMIN_USERS_STAGE_REVALU =
  "https://user-management-stage.revalu.world";
const BASE_HOST_ANALYTICS_STAGE = "analytics-service-stage.revalu.world";

// LIVE
const BASE_HOST_EPD_MANAGEMENT_LIVE = "epd.revalu.io";
const BASE_HOST_ADMIN_USERS_LIVE = "https://user-management.revalu.io";
const BASE_HOST_V2_LIVE = ".revalu.io";
const BASE_HOST_ANALYTICS_LIVE = "analytics-service.revalu.io";


export const BASE_URL_V2 = ((startPoint) => {
  if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'DEV'
    || process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'DEV_REVALU') {
    return "https://" + startPoint + "-" + BASE_HOST_V2_DEV_REVALU + "/";
  } else if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'STAGE'
    || process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'STAGE_REVALU') {
    return "https://" + startPoint + "-" + BASE_HOST_V2_STAGE_REVALU + "/";
  } else if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'LIVE') {
    return "https://" + startPoint + BASE_HOST_V2_LIVE + "/";
  }
});


export const BASE_URL_EPD = (() => {
  if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'DEV'
    || process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'DEV_REVALU') {
    return "https://" + BASE_HOST_EPD_MANAGEMENT_DEV_REVALU + "/";
  } else if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'STAGE'
    || process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'STAGE_REVALU') {
    return "https://" + BASE_HOST_EPD_MANAGEMENT_STAGE_REVALU + "/";
  } else if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'LIVE') {
    return "https://" + BASE_HOST_EPD_MANAGEMENT_LIVE + "/";
  }
})();

export const BASE_URL_ANALYTICS = (() => {
  if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'DEV'
    || process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'DEV_REVALU') {
    return "https://" + BASE_HOST_ANALYTICS_DEV + "/";
  } else if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'STAGE'
    || process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'STAGE_REVALU') {
    return "https://" + BASE_HOST_ANALYTICS_STAGE + "/";
  } else if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'LIVE') {
    return "https://" + BASE_HOST_ANALYTICS_LIVE + "/";
  }
})();

export const BASE_HOST_ADMIN_USERS = (() => {
  if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'DEV'
    || process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'DEV_REVALU') {
    return BASE_HOST_ADMIN_USERS_DEV_REVALU;
  } else if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'STAGE'
    || process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'STAGE_REVALU') {
    return BASE_HOST_ADMIN_USERS_STAGE_REVALU;
  } else if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'LIVE') {
    return BASE_HOST_ADMIN_USERS_LIVE;
  }
})();


const headers = {
  "Content-Type": "application/JSON",
  "Access-Control-Allow-Origin": "*",
  "X-Forwarded-Proto": "https",
};

const instance = axios.create({
  baseURL: BASE_URL,
  headers: headers,
});

export default instance;
