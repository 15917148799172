import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Box, CircularProgress, DialogContent } from "@mui/material";
import { getById } from "../../../store/Supplier/supplierSlice";
import AttributeDetails from "./attributeDetails";

const ViewDetails = (props) => {
  const dispatch = useDispatch();
  const { id } = props;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      (async () => {
        try {
          const res = await dispatch(getById(id));
          if (res) {
            setData(res);
          }
        } catch (ex) {
          dispatch(
            showMessage({ message: ex.message || ex, variant: "error" }),
          );
        } finally {
          setLoading(false);
        }
      })();
    }
  }, []);
  return (
    <DialogContent>
      {loading ? (
        <Box textAlign="center">
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <AttributeDetails data={data} />
      )}
    </DialogContent>
  );
};

export default ViewDetails;
