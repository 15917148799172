import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import { addUUIDInCustomMaterial } from "../../../store/CustomMaterial/customMaterial";
import { showMessage } from "../../../store/Revalu/notificationSlice";

const AddUUIDinCustomMaterial = (props) => {
  const { id, onClose, option = "epd", refreshPage = () => {} } = props;
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);

  const { loading } = useSelector(({ customMaterial }) => customMaterial.slice);

  const schema = yup.object().shape({
    uuid: yup.string(),
    id: yup.string(),
  });

  const defaultValues = {
    uuid: "",
    id: id,
  };

  const {
    control,
    formState,
    handleSubmit,
    setError,
    reset,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;

  const onSubmit = async (values) => {
    try {
      setSaving(true);
      const res = await dispatch(addUUIDInCustomMaterial(values));
      if (res) {
        dispatch(showMessage({ message: "UUID Added successfully" }));
        refreshPage();
      } else {
        throw new Error("An unknown error occurred");
      }
    } catch (ex) {
      dispatch(showMessage({ message: ex.message || ex, variant: "error" }));
    } finally {
      setSaving(false);
      onClose();
    }
  };

  return (
    <DialogContent>
      {loading ? (
        <Box textAlign="center">
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <label>UUID</label>
          <Controller
            name="uuid"
            control={control}
            className="textfield"
            render={({ field }) => (
              <TextField
                {...field}
                id="uuid-input"
                error={!!errors.uuid}
                helperText={errors?.uuid?.message}
                variant="outlined"
                placeholder="Enter UUID"
                required
                fullWidth
              />
            )}
          />
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginTop: "10px" }}
              disabled={!isValid}>
              {saving ? "Adding..." : "Add"}
            </Button>
          </DialogActions>
        </form>
      )}
    </DialogContent>
  );
};

export default AddUUIDinCustomMaterial;
