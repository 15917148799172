import { StyledEngineProvider } from "@mui/material/styles";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import App from "./components/App";
import "./index.css";

import { UserProvider } from "./context/UserContext";

import { Provider } from "react-redux";
import store from "./store";

ReactDOM.createRoot(document.querySelector("#root")).render(
  <Provider store={store}>
    <React.StrictMode>
      <StyledEngineProvider injectFirst>
        <UserProvider>
          <App />
        </UserProvider>
      </StyledEngineProvider>
    </React.StrictMode>
  </Provider>,
);

// reportWebVitals()
