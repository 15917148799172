import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import PasswordField from "../../../../common/Fields/PasswordField";
import {
  createAdminUser,
  resetState,
  updateAdminUser,
} from "../../../../store/AdminUser/adminUserSlice";
import { showMessage } from "../../../../store/Revalu/notificationSlice";

const PREFIX = "CreateAdminUserForm";

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  formWrapper: `${PREFIX}-formWrapper`,
  label: `${PREFIX}-label`,
  submitButton: `${PREFIX}-submitButton`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.wrapper}`]: {
    padding: 22,
    boxShadow: "0px 2px 5px #343D4F08",
    borderRadius: 12,
    "& .title": {
      fontSize: 17,
      fontWeight: 500,
      color: theme.palette.primary.fontColor,
    },
  },

  [`& .${classes.formWrapper}`]: {
    marginBottom: 13,
    "& .textfield": {
      border: "1px solid " + theme.palette.drawer.border,
      borderRadius: 13,
      background: theme.palette.drawer.textFieldBg,
      "& input": {
        padding: "7px 18px",
        height: 11,
      },
    },
  },

  [`& .${classes.label}`]: {
    color: theme.palette.primary.fontColor,
    fontWeight: 500,
    fontSize: 16,
    display: "block",
    marginBottom: 5,
  },

  [`& .${classes.submitButton}`]: {
    marginLeft: "auto",
    display: "block",
    marginTop: 13,
    "& span": {
      padding: 0,
    },
  },
}));

const CreateAdminUserForm = ({ currentRecord, onClose = () => {} }) => {
  const dispatch = useDispatch();
  const { createAdminUserLoading, createAdminUserSuccess, adminUserData } =
    useSelector(({ adminUser }) => adminUser.adminUserAll);
  const id = currentRecord?.id;
  const defaultValues = {
    firstName: currentRecord?.firstName || "",
    email: currentRecord?.email || "",
    lastName: currentRecord?.lastName || "",
    password: "",
    retypePassword: "",
    phoneNo: currentRecord?.phoneNo || "",
    address: currentRecord?.address || "",
    isActive: currentRecord ? currentRecord.isActive : true,
  };

  const editSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Required"),
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    phoneNo: yup.string().required("Required"),
    address: yup.string().required("Required"),
  });

  const addNewSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Required"),
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    phoneNo: yup.string().required("Required"),
    address: yup.string().required("Required"),
    password: yup.string().required("Required"),
    retypePassword: yup
      .string()
      .required("Required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const { control, formState, handleSubmit, setError, reset } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(Boolean(id) ? editSchema : addNewSchema),
  });
  const { isValid, dirtyFields, errors } = formState;

  const onResponse = (res) => {
    const { data, status, description } = res;
    if (Boolean(data) && Number(status) === 200) {
      dispatch(showMessage({ message: description }));
      reset();
      onClose();
    } else if (Number(status) === 400) {
      if (
        data &&
        data["database exception"]
          ?.toString()
          ?.toLowerCase()
          ?.indexOf("duplicate") > -1
      ) {
        dispatch(
          showMessage({ message: "Email already exists", variant: "error" }),
        );
      }
    }
  };

  const onSubmit = async (model) => {
    if (Boolean(id)) {
      const res = await dispatch(updateAdminUser(model, id, history));
      onResponse(res);
    } else {
      const res = await dispatch(createAdminUser(model, history));
      onResponse(res);
    }
  };

  useEffect(() => {
    if (createAdminUserSuccess && Boolean(adminUserData)) {
      reset();
      onClose();
    }
  }, [createAdminUserSuccess, adminUserData]);

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, []);

  return (
    <Root className={classes.wrapper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <div className={classes.formWrapper}>
              <label className={classes.label}>First Name*</label>
              <Controller
                name="firstName"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.firstName}
                    helperText={errors?.firstName?.message}
                    variant="outlined"
                    placeholder="First Name"
                    required
                    id="text-input-field"
                    sx={{ width: "100%" }}
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className={classes.formWrapper}>
              <label className={classes.label}>Last Name*</label>
              <Controller
                name="lastName"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.lastName}
                    helperText={errors?.lastName?.message}
                    variant="outlined"
                    placeholder="Last Name"
                    required
                    id="text-input-field"
                    sx={{ width: "100%" }}
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={12} md={12}>
            <div className={classes.formWrapper}>
              <label className={classes.label}>Email*</label>
              <Controller
                name="email"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    variant="outlined"
                    placeholder="Email"
                    disabled={Boolean(id)}
                    inputProps={{ maxLength: 40 }}
                    required
                    id="text-input-field"
                    sx={{ width: "100%" }}
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            {!Boolean(id) && (
              <>
                <div className={classes.formWrapper}>
                  <label className={classes.label}>Password*</label>
                  <Controller
                    name="password"
                    control={control}
                    className="textfield"
                    render={({ field }) => (
                      <PasswordField
                        {...field}
                        error={!!errors.password}
                        helperText={errors?.password?.message}
                        variant="outlined"
                        type="password"
                        placeholder="Password"
                        inputProps={{ maxLength: 40 }}
                        required
                        id="text-input-field"
                        sx={{ width: "100%" }}
                      />
                    )}
                  />
                </div>
                <div className={classes.formWrapper}>
                  <label className={classes.label}>Confirm Password*</label>
                  <Controller
                    name="retypePassword"
                    control={control}
                    className="textfield"
                    render={({ field }) => (
                      <PasswordField
                        {...field}
                        error={!!errors.retypePassword}
                        helperText={errors?.retypePassword?.message}
                        variant="outlined"
                        type="password"
                        placeholder="Confirm Password"
                        inputProps={{ maxLength: 40 }}
                        required
                        id="text-input-field"
                        sx={{ width: "100%" }}
                      />
                    )}
                  />
                </div>
              </>
            )}
            <div className={classes.formWrapper}>
              <label className={classes.label}>Phone no.*</label>
              <Controller
                name="phoneNo"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.phoneNo}
                    helperText={errors?.phoneNo?.message}
                    variant="outlined"
                    type="phone"
                    inputProps={{ maxLength: 15 }}
                    placeholder="Phone"
                    required
                    id="text-input-field"
                    sx={{ width: "100%" }}
                  />
                )}
              />
            </div>
            <div className={classes.formWrapper}>
              <label className={classes.label}>Address*</label>
              <Controller
                name="address"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.address}
                    helperText={errors?.address?.message}
                    variant="outlined"
                    placeholder="Address"
                    inputProps={{ maxLength: 240 }}
                    required
                    id="text-input-field"
                    sx={{ width: "100%" }}
                  />
                )}
              />
            </div>
            {!Boolean(id) && (
              <div className={classes.formWrapper}>
                <label className={classes.label}>Active?*</label>
                <Controller
                  name="isActive"
                  control={control}
                  className="textfield"
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          {...field}
                          inputProps={{ "aria-label": "controlled" }}
                          checked={field.value}
                        />
                      }
                    />
                  )}
                />
              </div>
            )}
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!isValid || createAdminUserLoading}
          className={classes.submitButton}>
          {createAdminUserLoading ? "Saving..." : "Save"}
        </Button>
      </form>
    </Root>
  );
};

export default CreateAdminUserForm;
