import { Box, Button, CircularProgress, DialogContent } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import MUIDataTable from "mui-datatables";
import {
  deleteTagInMaterial,
  getTagOfMaterial,
} from "../../../../store/MaterialTags/materialTags";
import { showMessage } from "../../../../store/Revalu/notificationSlice";

const PREFIX = "viewTags";

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  formWrapper: `${PREFIX}-formWrapper`,
  label: `${PREFIX}-label`,
  submitButton: `${PREFIX}-submitButton`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.wrapper}`]: {
    padding: 22,
    boxShadow: "0px 2px 5px #343D4F08",
    borderRadius: 12,
    "& .title": {
      fontSize: 17,
      fontWeight: 500,
      color: theme.palette.primary.fontColor,
    },
  },

  [`& .${classes.formWrapper}`]: {
    marginBottom: 13,
    "& .textfield": {
      border: "1px solid " + theme.palette.drawer.border,
      borderRadius: 13,
      background: theme.palette.drawer.textFieldBg,
      "& input": {
        padding: "7px 18px",
        height: 11,
      },
    },
  },

  [`& .${classes.label}`]: {
    color: theme.palette.primary.fontColor,
    fontWeight: 500,
    fontSize: 16,
    display: "block",
    marginBottom: 5,
  },

  [`& .${classes.submitButton}`]: {
    marginLeft: "auto",
    display: "block",
    marginTop: 13,
    "& span": {
      padding: 0,
    },
  },
}));

const ViewDocuments = (props) => {
  const dispatch = useDispatch();
  const { id, onClose } = props;
  const [data, setData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [currentRecord, setCurrentRecord] = useState({});
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const refresh = () => setUpdate((prev) => setUpdate(!prev));

  useEffect(() => {
    const fetchTags = async () => {
      setLoading(true);
      const allData = await dispatch(getTagOfMaterial(id));
      setLoading(false);
      if (allData.length) {
        setData(allData);
      } else {
        setData([]);
        setLoading(false);
      }
    };
    fetchTags();
  }, [id, dispatch, update]);

  const deleteDocument = async (values) => {
    setLoading(true);
    const tagMaterialTagDto = {
      materialTagDataSourceId: id,
      tagId: values?.id,
    };
    const res = await dispatch(deleteTagInMaterial(tagMaterialTagDto));
    setLoading(false);
    if (res) {
      dispatch(showMessage({ message: "Deleted Successfully" }));
      refresh();
    } else {
      setLoading(false);
      refresh();
      dispatch(showMessage({ message: "No Document Found" }));
    }
  };

  const headers = [
    { name: "Datasource", options: { sort: false } },
    { name: "Name", options: { sort: false } },

    {
      options: {
        sort: false,
        customBodyRender: (value) => {
          return (
            <Root className={classes.actionBtns}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  deleteDocument(value);
                }}>
                Delete
              </Button>
            </Root>
          );
        },
      },
    },
  ];

  const getParsedData = () => {
    return data?.map((m) => [
      m?.id,
      m?.name,
      {
        id: m?.id,
      },
    ]);
  };

  const options = {
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    checkbox: false,
    selectableRowsHeader: false,
    selectableRows: "none",
    filterType: "checkbox",
    serverSide: true,
    search: false,
    pagination: false,
  };

  return (
    <div>
      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(0,0,0,0.3)",
            display: "flex",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            padding: "30px 20px",
            zIndex: 999,
            "& >div": {
              zIndex: 1000,
            },
          }}>
          <CircularProgress />
        </Box>
      )}
      <DialogContent>
        <MUIDataTable
          title=""
          columns={headers}
          data={getParsedData()}
          options={{ ...options }}
        />
      </DialogContent>
    </div>
  );
};

export default ViewDocuments;
