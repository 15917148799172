import DashboardIcon from "@mui/icons-material/Dashboard";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCounts,
  getAllUserCounts,
} from "../../../store/Dashboard/dashboardSlice";
import Header from "../Common/header";
import Summary from "../Common/summary";

const DashboardLanding = (props) => {
  const dispatch = useDispatch();

  const viewAllEpdCounts = useSelector(
    ({ dashboard }) => dashboard.dashboardAll.dashboardData,
  );
  const viewAllUserCounts = useSelector(
    ({ dashboard }) => dashboard.dashboardAll.dashboardUserData,
  );

  useEffect(() => {
    const fetchEpdData = async () => {
      const data = {};
      const isValid = await dispatch(getAllCounts(data));
      return isValid;
    };
    const fetchUserData = async () => {
      const data = {};
      const isValid = await dispatch(getAllUserCounts(data));
      return isValid;
    };

    fetchEpdData();
    fetchUserData();
  }, [dispatch]);

  return (
    <>
      <Header icon={<DashboardIcon />} title="Dashboard" />
      <Summary
        viewAllEpdCounts={viewAllEpdCounts}
        totalUsers={viewAllUserCounts}
      />
    </>
  );
};

export default DashboardLanding;
