import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Grid, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import { Autocomplete } from "@mui/material";
import { setLoading } from "../../../store/Manufactures/manufactureSlice";
import { showMessage } from "../../../store/Revalu/notificationSlice";
import { linking, list } from "../../../store/Supplier/supplierSlice";

const PREFIX = "supplierDetails";

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  formWrapper: `${PREFIX}-formWrapper`,
  label: `${PREFIX}-label`,
  submitButton: `${PREFIX}-submitButton`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.wrapper}`]: {
    padding: 22,
    boxShadow: "0px 2px 5px #343D4F08",
    borderRadius: 12,
    "& .title": {
      fontSize: 17,
      fontWeight: 500,
      color: theme.palette.primary.fontColor,
    },
  },

  [`& .${classes.formWrapper}`]: {
    marginBottom: 13,
    "& .textfield": {
      border: "1px solid " + theme.palette.drawer.border,
      borderRadius: 13,
      background: theme.palette.drawer.textFieldBg,
      "& input": {
        padding: "7px 18px",
        height: 11,
      },
    },
  },

  [`& .${classes.label}`]: {
    color: theme.palette.primary.fontColor,
    fontWeight: 500,
    fontSize: 16,
    display: "block",
    marginBottom: 5,
  },

  [`& .${classes.submitButton}`]: {
    marginLeft: "auto",
    display: "block",
    marginTop: 13,
    "& span": {
      padding: 0,
    },
  },
}));

const SupplierDetails = (props) => {
  const dispatch = useDispatch();
  const { id, refresh } = props;
  const [contactList, setContactList] = useState("");
  const { data: supplierDataList, loading } = useSelector(
    ({ supplier }) => supplier.slice,
  );

  useEffect(() => {
    if (supplierDataList.size !== 20000) {
      dispatch(list({ pageNo: 0, pageSize: 20000, searchValue: "" }));
    }
  }, [id, dispatch]);

  const getContactName = () => {
    const names = supplierDataList.content.map((m) => m?.contactName);
    setContactList(names);
  };

  useEffect(() => {
    getContactName();
  }, [supplierDataList]);

  const schema = yup.object().shape({
    field: yup.string(),
    supplierId: yup.string(),
  });

  const defaultValues = {
    field: "",
    supplierId: "",
  };

  const {
    control,
    formState,
    handleSubmit,
    setError,
    reset,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;

  const getIdFromManufactureName = (name) => {
    const currentManufacturer = supplierDataList?.content.filter(
      (f) => f.contactName === name,
    );
    return currentManufacturer[0]?.id;
  };

  const handleChangeManufacturerAttribute = (e, name) => {
    setValue("field", name);
    const itemValue = getIdFromManufactureName(name);
    setValue("supplierId", itemValue);
  };

  const onResponse = (res) => {
    const { data, status, description } = res;
    // if (Boolean(data) && Number(status) === 200) {
    //   if (data?.[0] && data?.[0]["errorMessgae"]) {
    //     dispatch(
    //       showMessage({ message: data?.[0]["errorMessgae"], variant: "error" }),
    //     )
    //   } else {
    //     dispatch(showMessage({ message: "Successfully Linked" }))
    //   }
    //   reset()
    //   onClose()
    // } else if (Number(status) === 400) {
    //   if (
    //     data &&
    //     data["database exception"]
    //       ?.toString()
    //       ?.toLowerCase()
    //       ?.indexOf("duplicate") > -1
    //   ) {
    //     dispatch(
    //       showMessage({
    //         message: "Manufacturer already linked",
    //         variant: "error",
    //       }),
    //     )
    //   }
    // }
    refresh();
    dispatch(showMessage({ message: "Successfully Linked" }));
    dispatch(setLoading(false));
  };

  const onSubmit = async (values) => {
    const supplierId = values?.supplierId;
    const res = await dispatch(
      linking({
        manufacturerId: id,
        supplierId: supplierId,
      }),
    );
    onResponse(res);
  };

  return (
    <Root className={classes.pageWrapper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.formWrapper}>
              <Autocomplete
                onChange={handleChangeManufacturerAttribute}
                fullWidth
                // disablePortal
                id="fieldName"
                options={contactList}
                renderInput={(params) => (
                  <TextField
                    id="Supplier-info"
                    variant="outlined"
                    fullWidth
                    required
                    {...params}
                    label="Suppliers"
                  />
                )}
              />
            </div>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{ marginTop: "10px" }}
            disabled={!isValid}
            className={classes.submitButton}>
            {loading ? "Linking..." : "Link"}
          </Button>
        </Grid>
      </form>
    </Root>
  );
};

export default SupplierDetails;
