import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getImportProcessStatus,
  importLCA,
  list,
  searchData,
} from "../../../../../store/LCA/lcaSlice";
import { showMessage } from "../../../../../store/Revalu/notificationSlice";
import ActionButtons from "./actionButtons";
import ViewDetails from "./viewDetails";

const EcoPortalEPDs = (props) => {
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [selectedIdToView, setSelectedIdToView] = useState(null);
  const dispatch = useDispatch();
  const { data, loading, importLoading, importProcessStatus, searchValue } =
    useSelector(({ lca }) => lca.slice);
  const { totalElements } = data;

  useEffect(() => {
    searchValue ? setPageNo(0) : pageNo;
    dispatch(list({ pageNo, pageSize, searchValue }));
  }, [pageNo, pageSize, searchValue]);

  useEffect(() => {
    const getStatus = () => {
      dispatch(getImportProcessStatus());
    };
    getStatus();
    const interval = setInterval(() => {
      getStatus();
    }, 600000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleViewDetails = (id) => {
    setSelectedIdToView(id);
  };

  const handleCloseDetails = () => {
    setSelectedIdToView(null);
  };

  const headers = [
    { name: "UUID", options: { sort: false } },
    { name: "Name", options: { sort: false } },
    { name: "Platform", options: { sort: false } },
    {
      options: {
        sort: false,
        customBodyRender: (value) => {
          return (
            <ActionButtons
              onEdit={() => {}}
              onViewDetails={handleViewDetails}
              rowData={value}
            />
          );
        },
      },
    },
  ];
  const getParsedData = () => {
    return data?.content?.map((m) => [
      m?.processInformation?.dataSetInformation?.UUID,
      m?.processInformation?.dataSetInformation?.name?.baseName[0]?.value,
      m.platformName,
      m,
    ]);
  };

  const onResponse = (res) => {
    if (!res) {
      dispatch(showMessage({ message: "No response", variant: "error" }));
      return false;
    }
    const { status, statusText } = res;
    if (Number(status) === 400) {
      dispatch(
        showMessage({
          message: statusText || "An uknown error occurred",
          variant: "error",
        }),
      );
    } else if (typeof res === "string") {
      dispatch(
        showMessage({
          message: res,
        }),
      );
    }
  };

  const handleImport = async () => {
    try {
      const res = await dispatch(importLCA());
      onResponse(res);
    } catch (ex) {
      dispatch(showMessage({ message: ex.message || ex, variant: "error" }));
    }
  };

  const options = {
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    checkbox: false,
    selectableRowsHeader: false,
    selectableRows: "none",
    filterType: "checkbox",
    serverSide: true,
    rowsPerPageOptions: [7, 10, 12],
    onSearchClose: () => {
      dispatch(searchData({ search: "" }));
    },
    onSearchChange: (e) => {
      if (e === null) {
        dispatch(searchData({ search: "" }));
      } else {
        dispatch(searchData({ search: e }));
      }
    },
    onTableChange: (action, state) => {
      const { page, rowsPerPage } = state;
      switch (action) {
        case "changePage":
          setPageNo(page);
          break;
        case "changeRowsPerPage":
          setPageSize(rowsPerPage);
          break;
      }
    },
  };

  return (
    <div>
      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(0,0,0,0.3)",
            display: "flex",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            padding: "30px 20px",
            zIndex: 999,
            "& >div": {
              zIndex: 1000,
            },
          }}>
          <CircularProgress />
        </Box>
      )}
      <div>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "20px",
            mb: 2,
          }}>
          {importProcessStatus?.processing && (
            <div>Import process is in progress.</div>
          )}
          <Button
            variant="contained"
            color="primary"
            disabled={importLoading || importProcessStatus?.processing}
            onClick={handleImport}>
            {importLoading ? (
              <>
                <CircularProgress size={20} />
                &nbsp; Please wait
              </>
            ) : (
              "Start Import Process"
            )}
          </Button>
        </Box>
      </div>

      <div>
        <div>
          <MUIDataTable
            title="Original EPDs"
            columns={headers}
            data={getParsedData()}
            options={{ ...options, count: totalElements }}
            onPageChange={() => {}}
          />
        </div>
      </div>

      {Boolean(selectedIdToView) && (
        <Dialog
          open={Boolean(selectedIdToView)}
          maxWidth="lg"
          fullWidth
          onClose={(event, reason) => {
            if (reason === "escapeKeyDown") {
              handleCloseDetails();
            }
          }}
          scroll="body">
          <DialogTitle>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between">
              <Box>EPD Details</Box>
              <Box textAlign="right">
                <IconButton onClick={handleCloseDetails} size="large">
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <ViewDetails onClose={handleCloseDetails} id={selectedIdToView} />
        </Dialog>
      )}
    </div>
  );
};

export default EcoPortalEPDs;
