import { createSlice } from "@reduxjs/toolkit";
import { MappedEPDService } from "../../services/mappedEPDService";
import { showMessage } from "../Revalu/notificationSlice";

export const list = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  return MappedEPDService.list(data)
    .then((res) => {
      dispatch(setData(res));
    })
    .catch((errors) => {})
    .finally(() => {
      dispatch(setLoading(false));
      return true;
    });
};
export const updateList = (data) => async (dispatch) => {
  return MappedEPDService.list(data)
    .then((res) => {
      dispatch(setData(res));
    })
    .catch((errors) => {})
    .finally(() => {
      return true;
    });
};
export const getById = (id) => async (dispatch) => {
  return MappedEPDService.getById(id)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};
export const deleteById = (id) => async (dispatch) => {
  return MappedEPDService.deleteById(id)
    .then((res) => {
      if (Boolean(res?.deletedCount)) {
        dispatch(removeRecord(id));
      }
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};
export const update = (data) => async (dispatch) => {
  return MappedEPDService.update(data)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};
export const deleteKeyword = (data) => async (dispatch) => {
  return MappedEPDService.deleteKeyword(data)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};
export const changeBlockedStatus = (data) => async (dispatch) => {
  return MappedEPDService.changeBlockedStatus(data)
    .then((res) => {
      if (Boolean(res?.modifiedCount)) {
        dispatch(modifyRecord(data));
      }
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};
export const exportData = (params) => async (dispatch) => {
  dispatch(setExportLoading(true));
  return MappedEPDService.export(params)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    })
    .finally(() => {
      dispatch(setExportLoading(false));
    });
};
export const getExportProcessStatus = () => async (dispatch) => {
  return MappedEPDService.getExportProcessStatus()
    .then((res) => {
      dispatch(setExportProcessStatus(res));
      return res;
    })
    .catch((errors) => {
      dispatch(setExportProcessStatus(null));
      return errors;
    });
};
export const startExportProcess = () => async (dispatch) => {
  dispatch(setExportProcessLoading(true));
  return MappedEPDService.startExportProcess()
    .then(async (res) => {
      await dispatch(getExportProcessStatus());
      return res;
    })
    .catch((errors) => {
      return errors;
    })
    .finally(() => {
      dispatch(setExportProcessLoading(false));
    });
};
export const qualityCheck = (id) => (dispatch) => {
  return MappedEPDService.qualityCheck(id)
    .then((res) => {
      dispatch(
        showMessage({ message: res ? res : "Successfuly Quality Check" }),
        window.location.reload(),
      );
      window.location.reload();
      return true;
    })
    .catch((errors) => {
      dispatch(
        showMessage({ message: errors.data || "Error", variant: "error" }),
      );
      return errors;
    });
};
export const statusUpdate = (data, id) => async (dispatch) => {
  return MappedEPDService.statusUpdate(data, id)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

export const getElements = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  return MappedEPDService.getElements(data)
    .then((res) => {
      dispatch(setElements(res));
    })
    .catch((errors) => {})
    .finally(() => {
      dispatch(setLoading(false));
      return true;
    });
};

export const getBroaderCategories = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  return MappedEPDService.getBroaderCategories(id)
    .then((res) => {
      dispatch(setBroaderCategories(res));
    })
    .catch((errors) => {})
    .finally(() => {
      dispatch(setLoading(false));
      return true;
    });
};

export const getSubCategory = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  return MappedEPDService.getSubCategory(id)
    .then((res) => {
      dispatch(setSubCategory(res));
    })
    .catch((errors) => {})
    .finally(() => {
      dispatch(setLoading(false));
      return true;
    });
};

export const getCategoryHierarchyID = (data) => async (dispatch) => {
  return MappedEPDService.getCategoryHierarchyID(data)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

export const submitCategoryHierarchy = (data) => async (dispatch) => {
  return MappedEPDService.submitCategoryHierarchy(data)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

export const uploadDocument = (data) => async (dispatch) => {
  return MappedEPDService.uploadDocument(data)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

export const updateDocument = (id, data) => async (dispatch) => {
  return MappedEPDService.updateDocument(id, data)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    });
};

export const getMappedDocuments = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  return MappedEPDService.getMappedDocuments(id)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};

export const getMappedReferencedDocuments = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  return MappedEPDService.getMappedReferencedDocuments(id)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};

export const deleteMappedDocument = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  return MappedEPDService.deleteMappedDocument(id)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};

export const deleteReferenceMappedDocument = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  return MappedEPDService.deleteReferenceMappedDocument(id)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};

export const createReferenceProjectDocument = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  return MappedEPDService.createReferenceProjectDocument(data)
    .then((res) => {
      return res;
    })
    .catch((errors) => {
      return errors;
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};

const initialState = {
  data: {
    content: [],
    totalPages: 0,
    totalElements: 0,
  },
  originalData: {
    content: [],
    totalPages: 0,
    totalElements: 0,
  },
  loading: false,
  exportLoading: false,
  exportProcessLoading: false,
  searchValue: "",
  exportProcessStatus: null,
  elementsData: "",
  broaderCategoriesData: "",
  subCategoryData: "",
  getCategoryHierarchyData: "",
};

const mappedEPDSlice = createSlice({
  name: "mappedEPD/listing",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
      state.originalData = action.payload;
    },
    modifyRecord: (state, action) => {
      const { id, blocked } = action.payload;
      const index = state.data.content.findIndex(
        (f) => f["Data Source ID"] === id,
      );
      if (index > -1) {
        state.data.content[index].blocked = blocked;
        state.originalData.content[index].blocked = blocked;
      }
    },
    removeRecord: (state, action) => {
      const id = action.payload;
      const index = state.data.content.findIndex(
        (f) => f["Data Source ID"] === id,
      );
      if (index > -1) {
        state.data.content.splice(index, 1);
        state.originalData.content.splice(index, 1);
      }
    },
    setExportProcessStatus: (state, action) => {
      state.exportProcessStatus = action.payload;
    },
    setExportLoading: (state, action) => {
      state.exportLoading = action.payload;
    },
    setExportProcessLoading: (state, action) => {
      state.exportProcessLoading = action.payload;
    },
    // searchData: (state, action) => {
    //   let { search } = action.payload
    //   if (!search) {
    //     state.data = state.originalData
    //     return
    //   }
    //   search = search.toLowerCase()
    //   const searchData = state.originalData.content.filter((f) => {
    //     return (
    //       f["Name (no)"] && f["Name (no)"].toLowerCase().indexOf(search) > -1
    //     )
    //   })
    //   state.data = {
    //     content: searchData,
    //     totalPages: 1,
    //     totalElements: searchData.length,
    //   }
    // },
    searchData: (state, action) => {
      const { search } = action.payload;
      state.searchValue = search;
    },
    setElements: (state, action) => {
      state.elementsData = action.payload;
    },
    setBroaderCategories: (state, action) => {
      state.broaderCategoriesData = action.payload;
    },
    setSubCategory: (state, action) => {
      state.subCategoryData = action.payload;
    },
    setCategoryHierarchyData: (state, action) => {
      state.getCategoryHierarchyData = action.payload;
    },
  },
});

export const {
  setLoading,
  setData,
  modifyRecord,
  setExportLoading,
  setExportProcessStatus,
  setExportProcessLoading,
  searchData,
  setElements,
  setBroaderCategories,
  setSubCategory,
  removeRecord,
} = mappedEPDSlice.actions;

export default mappedEPDSlice.reducer;
