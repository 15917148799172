import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { USER_TIERS } from "../../../store/App/constants";
import { setUserTier } from "../../../store/RegisteredUser/RegisteredUserSlice";
import { showMessage } from "../../../store/Revalu/notificationSlice";

export const ChangeUserTier = ({ open, setOpen, selectedRow }) => {
  const [selectedTier, setSelectedTier] = useState(
    Array.isArray(selectedRow) ? selectedRow[8] : selectedRow?.userTier,
  );
  const [apiCode, setApiCode] = useState("");
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
  };

  const submitSetUserTier = async () => {
    try {
      const userId = Array.isArray(selectedRow)
        ? selectedRow[0]
        : selectedRow?.id;
      if (!userId) {
        throw new Error("No user selected!");
      }
      const res = await dispatch(setUserTier(selectedTier, userId, apiCode));
      if (res === "Done") {
        dispatch(showMessage({ message: "User tier updated" }));
        handleClose();
      } else {
        dispatch(
          showMessage({
            message: "Error in changing user tier, please try again",
            variant: "error",
          }),
        );
      }
    } catch (err) {
      console.error(err.data);
      dispatch(
        showMessage({
          message: err.data["database exception"],
          variant: "error",
        }),
      );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"md"}
      // fullWidth={true}
    >
      <DialogTitle id="alert-dialog-title">{`Set user tier for user_id ${
        Array.isArray(selectedRow) ? selectedRow[0] : selectedRow?.id
      }`}</DialogTitle>
      <form onSubmit={submitSetUserTier}>
        <DialogContent>
          <TextField
            id="outlined-select-currency"
            sx={{ paddingBottom: 2, pr: 2 }}
            select
            label="Permissions"
            value={selectedTier}
            onChange={(event) => setSelectedTier(event.target.value)}
            helperText="Select the user tier">
            {Object.entries(USER_TIERS).map((option) => (
              <MenuItem key={option[0]} value={option[0]}>
                {option[1]}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="api-code"
            placeholder="API Code"
            label="API Code"
            value={apiCode}
            required
            helperText="For security reasons api code has to be entered"
            onChange={(event) => setApiCode(event.target.value)}></TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" disabled={apiCode === ""}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
