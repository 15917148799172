import React, { useEffect, useState } from "react";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  list,
  resetSelected,
  searchData,
  setSelected,
} from "../../../store/Supplier/supplierSlice";

// material ui
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
} from "@mui/material";
import MUIDataTable from "mui-datatables";

// imports
import { DialogHeader } from "../Common/dialogHeader";
import ActionButtons from "./actionButtons";
import CreateContactForm from "./createSupplier";
import TableActionButton from "./tableActionButton";
import ViewDetails from "./viewDetails";

const AllSupplier = (props) => {
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const dispatch = useDispatch();

  const {
    data,
    loading,
    selected: currentRecord,
    searchValue,
  } = useSelector(({ supplier }) => supplier.slice);

  const { totalElements } = data;
  const [selectedId, setSelectedId] = useState(null);
  const [selectedIdToView, setSelectedIdToView] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [update, setUpdate] = useState(false);
  const refreshList = () => setUpdate((prev) => setUpdate(!prev));

  const handleSelect = (id) => {
    dispatch(setSelected({ id }));
    setSelectedId(id);
    setShowAddModal(true);
  };

  const handleViewDetails = (id) => {
    setSelectedIdToView(id);
  };

  const handleCloseDetails = () => {
    setSelectedIdToView(null);
  };

  const hideModal = () => {
    setShowModal(false);
    setShowAddModal(false);
    dispatch(resetSelected());
  };

  const updateTable = () => {
    dispatch(list({ pageNo, pageSize, searchValue }));
  };

  useEffect(() => {
    searchValue ? setPageNo(0) : pageNo;
    updateTable();
  }, [pageNo, pageSize, searchValue, update]);

  const headers = [
    { name: "Contact Name", options: { sort: false } },
    { name: "Email", options: { sort: false } },
    { name: "Telephone No", options: { sort: false } },
    { name: "Street", options: { sort: false } },
    { name: "City", options: { sort: false } },
    { name: "Country", options: { sort: false } },
    { name: "Zip Code", options: { sort: false } },
    {
      options: {
        sort: false,
        customBodyRender: (value) => {
          return (
            <ActionButtons
              onEdit={handleSelect}
              onViewDetails={handleViewDetails}
              rowData={value}
              refreshList={refreshList}
            />
          );
        },
      },
    },
  ];
  const getParsedData = () => {
    return data?.content?.map((m) => [
      m.contactName,
      m?.email,
      m?.telephoneNo,
      m?.street,
      m?.city,
      m?.country,
      m?.zipCode,
      {
        id: m?.id,
      },
    ]);
  };

  const options = {
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    checkbox: false,
    selectableRowsHeader: false,
    selectableRows: "none",
    filterType: "checkbox",
    serverSide: true,
    search: true,
    rowsPerPageOptions: [10, 20, 50],
    onSearchClose: () => {
      dispatch(searchData({ search: "" }));
    },
    onSearchChange: (e) => {
      if (e === null) {
        dispatch(searchData({ search: "" }));
      } else {
        dispatch(searchData({ search: e }));
      }
    },
    onTableChange: (action, state) => {
      const { page, rowsPerPage } = state;
      switch (action) {
        case "changePage":
          setPageNo(page);
          break;
        case "changeRowsPerPage":
          setPageSize(rowsPerPage);
          break;
      }
    },
  };

  return (
    <div>
      <div>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "20px",
            mb: 2,
          }}>
          <Box
            display="inline-flex"
            justifyContent="flex-end"
            gap="10px"
            flexWrap="wrap">
            <TableActionButton
              onClick={() => {
                setShowAddModal(true);
              }}
              title="Add Supplier"
            />
          </Box>
        </Box>
      </div>

      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(0,0,0,0.3)",
            display: "flex",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            padding: "30px 20px",
            zIndex: 999,
            "& >div": {
              zIndex: 1000,
            },
          }}>
          <CircularProgress />
        </Box>
      )}

      <div>
        <div>
          <MUIDataTable
            title="Supplier for Manufacturers"
            columns={headers}
            data={getParsedData()}
            options={{ ...options, count: totalElements }}
            onPageChange={() => {}}
          />
        </div>
      </div>
      {showAddModal && (
        <Dialog
          aria-labelledby="admin-user-form-dialog"
          onClose={hideModal}
          open={showAddModal}>
          <DialogTitle>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between">
              <Box>
                {Boolean(currentRecord) ? "Update" : "Create"} Supplier for
                Manufacturer
              </Box>
              <Box textAlign="right">
                <IconButton onClick={hideModal} size="large">
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <CreateContactForm
            currentRecord={currentRecord}
            onClose={hideModal}
            refreshList={refreshList}
          />
        </Dialog>
      )}

      {Boolean(selectedIdToView) && (
        <Dialog
          open={Boolean(selectedIdToView)}
          maxWidth="lg"
          fullWidth
          onClose={handleCloseDetails}
          scroll="body">
          <DialogHeader
            onClose={handleCloseDetails}
            title={"Supplier Details"}
          />
          {/* <DialogTitle>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between">
              <Box>Supplier Details</Box>
              <Box textAlign="right">
                <IconButton onClick={handleCloseDetails} size="large">
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle> */}
          <ViewDetails onClose={handleCloseDetails} id={selectedIdToView} />
        </Dialog>
      )}
    </div>
  );
};

export default AllSupplier;
