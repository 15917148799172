import { yupResolver } from "@hookform/resolvers/yup";
import { Button, DialogActions, DialogContent, TextField } from "@mui/material";
import React from "react";
import * as yup from "yup";

import { Controller, useForm } from "react-hook-form";

const schema = yup.object().shape({
  apiKey: yup
    .string()
    .min(
      20,
      "API key should have at least 20 characters and maximum 300 characters",
    )
    .max(
      300,
      "API key should have at least 20 characters and maximum 300 characters",
    ),
});

const AddAPIKey = (props) => {
  const { onAddAPIKey = () => {}, loading } = props;
  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues: { apiKey: "" },
    resolver: yupResolver(schema),
  });
  const { isValid, errors } = formState;

  return (
    <div>
      <form onSubmit={handleSubmit(onAddAPIKey)}>
        <DialogContent>
          <div>
            <Controller
              name="apiKey"
              control={control}
              className="textfield"
              render={({ field }) => (
                <TextField
                  {...field}
                  id="add-api-key"
                  error={!!errors.apiKey}
                  helperText={errors?.apiKey?.message}
                  variant="outlined"
                  placeholder="API Key*"
                  label="API Key"
                  required
                  sx={{ width: "100%" }}
                />
              )}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!isValid || loading}
            style={{ marginTop: "10px" }}>
            {loading ? "Saving..." : "Save"}
          </Button>
        </DialogActions>
      </form>
    </div>
  );
};

export default AddAPIKey;
