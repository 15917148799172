import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Grid, MenuItem, Select } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { statusUpdate } from "../../../store/MappedEDPs/mappedEPDSlice";
// import PasswordField from "../../../common/Fields/PasswordField"
import { showMessage } from "../../../store/Revalu/notificationSlice";

const PREFIX = "QualitystatusForm";

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  formWrapper: `${PREFIX}-formWrapper`,
  label: `${PREFIX}-label`,
  submitButton: `${PREFIX}-submitButton`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.wrapper}`]: {
    padding: 22,
    boxShadow: "0px 2px 5px #343D4F08",
    borderRadius: 12,
    "& .title": {
      fontSize: 17,
      fontWeight: 500,
      color: theme.palette.primary.fontColor,
    },
  },

  [`& .${classes.formWrapper}`]: {
    marginBottom: 13,
    "& .textfield": {
      border: "1px solid " + theme.palette.drawer.border,
      borderRadius: 13,
      background: theme.palette.drawer.textFieldBg,
      "& input": {
        padding: "7px 18px",
        height: 11,
      },
    },
  },

  [`& .${classes.label}`]: {
    color: theme.palette.primary.fontColor,
    fontWeight: 500,
    fontSize: 16,
    display: "block",
    marginBottom: 5,
  },

  [`& .${classes.submitButton}`]: {
    marginLeft: "auto",
    display: "block",
    marginTop: 13,
    "& span": {
      padding: 0,
    },
  },
}));

const QualitystatusForm = ({ qualityStatusValue, onClose = () => {} }) => {
  const dispatch = useDispatch();
  const [statusValue, setStatusValue] = useState("");
  const navigate = useNavigate();
  const { recordLoading } = useSelector(({ objectType }) => objectType.slice);
  const id = qualityStatusValue?.id;
  const defaultValues = {
    name: qualityStatusValue?.status || "",
  };

  const schema = yup.object().shape({
    name: yup.string().required("Required"),
  });

  const { control, formState, handleSubmit, setError, reset } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, errors } = formState;

  const onResponse = (res) => {
    const { id, status, data } = res;
    if (Number(status) === 400) {
      dispatch(showMessage({ message: "Error", variant: "error" }));
      onClose();
    }
    dispatch(showMessage({ message: "Updated successfully" }));
    onClose();
    window.location.reload();
  };

  const onSubmit = async (model) => {
    const status = {
      value: statusValue ? statusValue : model.name,
    };
    const res = await dispatch(statusUpdate(status, id, history));
    onResponse(res);
  };

  const onChangeStatus = (event) => {
    setStatusValue(event.target.value);
  };

  return (
    <Root className={classes.wrapper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <div className={classes.formWrapper}>
              <label className={classes.label}>Status*</label>
              <Controller
                name="name"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <Select
                    error={!!errors.name}
                    labelId="demo-simple-select-label"
                    variant="outlined"
                    value={
                      statusValue ? statusValue : qualityStatusValue?.status
                    }
                    label="Name"
                    onChange={onChangeStatus}>
                    <MenuItem value={"Success"}>Success</MenuItem>
                    <MenuItem value={"Warning"}>Warning</MenuItem>
                  </Select>
                )}
              />
            </div>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!isValid || recordLoading}
          className={classes.submitButton}>
          {recordLoading ? "Saving..." : "Save"}
        </Button>
      </form>
    </Root>
  );
};

export default QualitystatusForm;
