import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CircularProgress, IconButton, Menu, MenuItem } from "@mui/material";
import * as React from "react";
import { useDispatch } from "react-redux";
import AlertDialog from "../../../../common/AlertDialog/AlertDialog";
import { setConfirmation } from "../../../../store/AlertDialog/alertDialogSlice";
import {
  changeBlockedStatus,
  deleteById,
  list,
} from "../../../../store/MappedEDPs/mappedEPDSlice";
import { showMessage } from "../../../../store/Revalu/notificationSlice";

const ITEM_HEIGHT = 48;
const STATUS = {
  WARNING: "Warning",
  SUCCESS: "Success",
  ERROR: "error",
};

function ActionButtons(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [alert, setAlert] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const open = Boolean(anchorEl);
  const {
    classes,
    rowData,
    onEdit = () => {},
    onViewDetails = () => {},
    onViewWarnings = () => {},
    onViewErrors = () => {},
    onQualityCheck = () => {},
    onCategoryEpd = () => {},
    onQualityStatus = () => {},
    onCategoryHierarchy = () => {},
    onManufacturerLinking = () => {},
    onUploadDocument = () => {},
    onViewDocuments = () => {},
    handleAddTag = () => {},
    handleViewTag = () => {},
    setSelectedIdToViewChangelog = () => {},
  } = props;
  const { id, blocked, status } = rowData;

  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEdit = () => {
    onEdit(id);
    handleClose();
  };
  const handleViewDetails = () => {
    onViewDetails(id);
    handleClose();
  };
  const handleViewWarnings = () => {
    onViewWarnings(id);
    handleClose();
  };

  const handleViewErrors = () => {
    onViewErrors(id);
    handleClose();
  };

  const handlequalitycheck = () => {
    onQualityCheck(id);
  };
  const handleCategoryEpd = () => {
    onCategoryEpd(id);
  };

  const handlequalitystatus = () => {
    onQualityStatus(id, status);
    handleClose();
  };

  const handleCategoryHierarchy = () => {
    onCategoryHierarchy(id, status);
    handleClose();
  };

  const handleDelete = () => {
    setAlert(true);
    handleClose();
  };
  const handleCancel = () => {
    dispatch(setConfirmation(false));
    setAlert(false);
  };

  const handleManufacturerLinking = () => {
    onManufacturerLinking(id);
  };

  const handleMappedDocumentLinking = () => {
    onUploadDocument(id);
  };

  const handleViewDocuments = () => {
    onViewDocuments(id);
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);
      setAlert(false);
      const res = await dispatch(deleteById(id));
      if (Boolean(res?.deletedCount)) {
        dispatch(showMessage({ message: "Record deleted" }));
        await dispatch(list());
      } else {
        dispatch(
          showMessage({
            message: "An unknown error occurred",
            variant: "error",
          }),
        );
      }
    } catch (ex) {
      dispatch(
        showMessage({
          message: ex.message ? ex.messge : ex,
          variant: "error",
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const hanldeChangeStatus = async () => {
    try {
      handleClose();
      setLoading(true);
      const res = await dispatch(
        changeBlockedStatus({ id, blocked: !blocked }),
      );
      if (Boolean(res?.modifiedCount)) {
        dispatch(
          showMessage({
            message: `${!blocked ? "Blocked" : "Unblocked"} successfully`,
          }),
        );
      }
    } catch (ex) {
      dispatch(showMessage({ message: ex.message || ex, variant: "error" }));
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <CircularProgress size={20} color="primary" />;
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        size="large">
        <MoreVertIcon />
      </IconButton>
      {open ? (
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          style={{
            marginTop: 10,
            maxHeight: ITEM_HEIGHT * 10.0,
            width: "33ch",
          }}>
          <MenuItem onClick={hanldeChangeStatus}>
            {blocked ? "Unblock" : "Block"}
          </MenuItem>
          <MenuItem onClick={handleEdit}>Edit</MenuItem>
          <MenuItem onClick={handleDelete}>Delete</MenuItem>
          <MenuItem onClick={handleViewDetails}>View Details</MenuItem>
          {status &&
            (status.toLowerCase() === STATUS.WARNING.toLowerCase() ||
              status.toLowerCase() === STATUS.ERROR.toLowerCase()) && (
              <MenuItem onClick={handleViewWarnings}>View Alerts</MenuItem>
            )}

          <MenuItem onClick={handleManufacturerLinking}>
            Manufacturer Linking
          </MenuItem>
          <MenuItem onClick={() => handleAddTag(id)}>Assign Tag</MenuItem>
          <MenuItem onClick={() => handleViewTag(id)}>
            View Assign Tags
          </MenuItem>
          <MenuItem onClick={handleMappedDocumentLinking}>
            Upload Document
          </MenuItem>
          <MenuItem onClick={handleViewDocuments}>View Documents</MenuItem>
          <MenuItem onClick={handlequalitycheck}>Start Quality Check</MenuItem>
          <MenuItem onClick={handleCategoryEpd}>
            Start EPD categorization
          </MenuItem>
          {/* <MenuItem onClick={handleCategoryHierarchy}>
          Add/Update Category Hierarchy
        </MenuItem> */}
          {status &&
            status.toLowerCase() !== STATUS.SUCCESS.toLocaleLowerCase() && (
              <MenuItem onClick={handlequalitystatus}>
                Update Data Quality Status
              </MenuItem>
            )}
          <MenuItem onClick={() => setSelectedIdToViewChangelog(id)}>
            View EPD Changelog
          </MenuItem>
        </Menu>
      ) : (
        ""
      )}

      {alert ? (
        <AlertDialog
          title="Delete EPD"
          message="Are you sure want to delete this EPD?"
          onCancel={handleCancel}
          onConfirm={handleConfirm}
        />
      ) : (
        ""
      )}
    </div>
  );
}
export default ActionButtons;
