import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

const PREFIX = "warningItem";

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  code: `${PREFIX}-code`,
  field: `${PREFIX}-field`,
  pageWrapper: `${PREFIX}-pageWrapper`,
  loader: `${PREFIX}-loader`,
  hidden: `${PREFIX}-hidden`,
  headerContent: `${PREFIX}-headerContent`,
  headerOptions: `${PREFIX}-headerOptions`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.wrapper}`]: {
    "& p": {
      wordBreak: "break-all",
    },
    "&:not(:last-child)": {
      borderBottom: "1px solid " + theme.palette.drawer.textFieldBg,
    },
  },

  [`& .${classes.code}`]: {
    padding: "7px 10px",
    fontSize: "12px",
    fontWeight: "bold",
    borderRadius: "4px",
    backgroundColor: theme.palette.primary.lighter,
    color: theme.palette.primary.main,
  },

  [`& .${classes.field}`]: {
    padding: "7px 10px",
    fontSize: "12px",
    fontWeight: "bold",
    borderRadius: "4px",
    backgroundColor: theme.palette.primary.lighter,
    color: theme.palette.primary.main,
  },

  [`& .${classes.pageWrapper}`]: {
    padding: 22,
    position: "relative",
    marginBottom: 32,
    "& .title": {
      color: theme.palette.primary.heading,
      fontSize: 20,
    },
  },

  [`& .${classes.loader}`]: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "rgba(0,0,0,0.3)",
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    padding: "30px 20px",
    zIndex: 999,
    "& >div": {
      zIndex: 1000,
    },
  },

  [`& .${classes.hidden}`]: {
    display: "none",
  },

  [`& .${classes.headerContent}`]: {
    display: "flex",
    background: theme.palette.headers.background,
    alignItems: "center",
    padding: "11px 17px",
    "& button": {
      minWidth: 100,
    },
    "& .lightButton": {
      background: theme.palette.headers.button.background,
      color: theme.palette.headers.button.color,
    },
    "& .activeButton": {
      background: theme.palette.headers.activeButton.background,
      color: theme.palette.headers.activeButton.color,
    },
  },

  [`& .${classes.headerOptions}`]: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "20px",
    "& svg": {
      color: theme.palette.headers.icons,
      fontSize: 17,
      cursor: "pointer",
    },
  },
}));

const WarningItem = ({ data }) => {
  const { test_code, field, message, value, test_name, mv_test_name } = data;

  return (
    <Root className={classes.wrapper}>
      <p>
        {mv_test_name ? (
          <>
            <strong>Multivariate Test:</strong> <label>{mv_test_name}</label>
          </>
        ) : (
          <></>
        )}
        <br></br>
        <strong>Field:</strong> <label>{field}</label>
      </p>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <p>
            <strong>Code:</strong>{" "}
            <label className={classes.code}>{test_code}</label>
          </p>
          <p>
            <strong>Name:</strong>{" "}
            <label className={classes.code}>{test_name}</label>
          </p>
        </Grid>
        <Grid item md={8}>
          <p>
            <strong>Message:</strong> {message}
          </p>

          <p>
            <strong>Value:</strong> {value}
          </p>
        </Grid>
      </Grid>
    </Root>
  );
};

export default WarningItem;
