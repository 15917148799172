import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { useNavigate } from "react-router-dom";

const PREFIX = "Summary";

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  heading: `${PREFIX}-heading`,
  boxes: `${PREFIX}-boxes`,
  graphBox: `${PREFIX}-graphBox`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.wrapper}`]: {
    padding: "22px 22px 11px 22px",
    width: "auto",
  },

  [`& .${classes.heading}`]: {
    display: "flex",
    alignItems: "center",
    width: "auto",
    marginBottom: 13,
    "& h1": {
      fontSize: 18,
      color: theme.palette.primary.heading,
      margin: 0,
      display: "flex",
    },
  },

  [`& .${classes.boxes}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .box": {
      padding: "16px 24px",
      borderRadius: 12,
      maxWidth: 180,
      background: theme.palette.summary.background,
      "& .title": {
        color: theme.palette.primary.fontColor,
        fontSize: 13,
      },
      "& .count": {
        color: theme.palette.primary.heading,
        fontSize: 22,
        fontWeight: 800,
      },
      "&:hover": {
        background: theme.palette.primary.main,
        "& span": {
          color: "white",
        },
      },
      "& span": {
        display: "block",
      },
    },
  },

  [`& .${classes.graphBox}`]: {},
}));

const Summary = (props) => {
  const navigate = useNavigate();

  const { viewAllEpdCounts, totalUsers } = props;
  return (
    <Root>
      <div className={classes.wrapper}>
        <div className={classes.heading}>
          <h1>Summary</h1>
        </div>
        <div className={classes.boxes}>
          <div className="box">
            <span className="title">Total number of Original EPDs</span>
            <span className="count">{viewAllEpdCounts.ecoPortalEpdCount}</span>
          </div>
          <div className="box" onClick={() => navigate(Routes.lca.mappedEPDs)}>
            <span className="title">Total Number of Mapped EPDs</span>
            <span className="count">{viewAllEpdCounts.mappedEpdCount}</span>
          </div>
          <div className="box">
            <span className="title">Quality Service Data Warnings</span>
            <span className="count">
              {viewAllEpdCounts.qualityWarningCount}
            </span>
          </div>
          <div
            className="box"
            onClick={() => navigate(Routes.user.platformUsers)}>
            <span className="title">Total number of users</span>
            <span className="count">{totalUsers}</span>
          </div>
        </div>

        <div>
          <Grid container>
            <Grid item xs={12} md={6}>
              <div className={classes.graphBox}></div>
            </Grid>
            <Grid item xs={12} md={6}></Grid>
          </Grid>
        </div>
      </div>
    </Root>
  );
};

export default Summary;
