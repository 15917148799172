import DOMPurify from "dompurify";

export const renderValueAttribute = (val, attribute) => {
  if (val instanceof Object) {
    const text = [];
    Object.keys(val).map((m) => {
      if (val[m] instanceof Object) {
        return renderValue(val[m], attribute);
      } else {
        return text.push(`${m}: ${val[m]}`);
      }
    });
    return text.join(", ");
  }
  if (typeof val == "boolean") {
    return val.toString();
  }

  if (attribute === "Custom Image") {
    return val.split("?")[0];
  }

  if (attribute == "epdUri") {
    const tempVal = val.split("?")[0];
    return tempVal.split("/").at(-1);
  }
  if (/<[a-z][\s\S]*>/i.test(val)) {
    let sanitizedHtml = DOMPurify.sanitize(val, {
      ALLOWED_TAGS: [
        "a",
        "p",
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "ul",
        "ol",
        "li",
        "div",
        "span",
        "strong",
        "em",
        "br",
        "img",
        "table",
        "thead",
        "tbody",
        "tr",
        "th",
        "td",
        "blockquote",
        "code",
        "pre",
      ],
      ALLOWED_ATTR: ["href", "target", "rel", "src", "alt", "title", "style"],
    });

    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = sanitizedHtml;
    const anchor = tempDiv.querySelector("a");
    if (anchor && !anchor.textContent.trim()) {
      anchor.textContent = anchor.href || "Click here";
      sanitizedHtml = tempDiv.innerHTML;
    }

    return <span dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
  }

  if (
    [
      "epdInsertDate",
      "epdUpdateDate",
      "discoverySpaceInsertDate",
      "dateInserted",
      "dateLastUpdated",
      "featuredManufacturerInsertDate",
      "EPD_VERSION_CHANGE_DATE",
      "createdDate",
      "validFrom",
      "validUntil",
    ].includes(attribute)
  ) {
    return new Date(parseInt(val)).toUTCString();
  }

  return val;
};

export const renderValue = (val) => {
  if (val instanceof Object) {
    const text = [];
    Object.keys(val).map((m) => {
      if (val[m] instanceof Object) {
        return renderValue(val[m]);
      } else {
        return text.push(`${m}: ${val[m]}`);
      }
    });
    return text.join(", ");
  }
  if (typeof val == "boolean") {
    return val.toString();
  }
  return val;
};
