import { styled } from "@mui/material/styles";
import React from "react";

const PREFIX = "Header";

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  heading: `${PREFIX}-heading`,
  settings: `${PREFIX}-settings`,
  titleWithSettings: `${PREFIX}-titleWithSettings`,
  buttonGroup: `${PREFIX}-buttonGroup`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.wrapper}`]: {
    background: theme.palette.menu.background,
    padding: "22px 22px 11px 22px",
    width: "auto",
    borderBottom: "1px solid " + theme.palette.menu.border,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  [`& .${classes.heading}`]: {
    display: "flex",
    alignItems: "center",
    width: "auto",
    "& h1": {
      color: theme.palette.primary.heading,
      margin: 0,
      display: "flex",
      fontSize: 22,
      alignItems: "center",
      "& svg": {
        color: theme.palette.primary.main,
        marginRight: 17,
      },
    },
  },

  [`& .${classes.settings}`]: {
    display: "flex",
    "& .box": {
      background: theme.palette.headers.settings.background,
      color: theme.palette.headers.settings.color,
      border: "1px solid " + theme.palette.headers.settings.border,
      padding: "4px 13px",
      borderRadius: 14,
      fontSize: 11,
      marginLeft: 17,
      textAlign: "center",
    },
    "& .icon": {
      color: theme.palette.primary.heading,
      margin: "0px 17px",
      cursor: "pointer",
    },
  },

  [`& .${classes.titleWithSettings}`]: {
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.buttonGroup}`]: {
    width: "auto",
    border: "1px solid " + theme.palette.headers.buttonGroup.border,
    borderRadius: 20,
    "& button": {
      color: theme.palette.headers.buttonGroup.color,
      fontSize: 11,
      border: "none",
      "&:hover": {
        border: "none",
      },
    },
  },
}));

const Header = (props) => {
  return (
    <Root>
      <div className={classes.wrapper}>
        <div className={classes.titleWithSettings}>
          <div className={classes.heading}>
            <h1>
              {props.icon} {props.title}
            </h1>
          </div>
          {/* <div className={classes.settings}>
            <div className="box" style={{ width: 148 }}>
              <span>Time internal : 1hour</span>
            </div>
            <div className="box" style={{ width: 238 }}>
              <span>Time Range : dd.mm.yyyy ~ dd.mm.yyyy</span>
            </div>
            <SettingsIcon className="icon" />
          </div> */}
        </div>
        {/* <div className={classes.buttonGroup}>
          <ButtonGroup color="primary">
            <Button>ENG</Button>
            <Button>GER</Button>
          </ButtonGroup>
        </div> */}
      </div>
    </Root>
  );
};

export default Header;
