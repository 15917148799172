import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Switch, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import {
  create,
  resetSelected,
  update,
} from "../../../store/Manufactures/manufactureSlice";

import { useState } from "react";
import { showMessage } from "../../../store/Revalu/notificationSlice";
import { handleUploadFile } from "../../../utils/fileUpload";

const PREFIX = "CreateManufacturerForm";

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  formWrapper: `${PREFIX}-formWrapper`,
  label: `${PREFIX}-label`,
  submitButton: `${PREFIX}-submitButton`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.wrapper}`]: {
    padding: 22,
    boxShadow: "0px 2px 5pxrgba(26, 26, 27, 0.03)",
    borderRadius: 12,
    "& .title": {
      fontSize: 17,
      fontWeight: 500,
      color: theme.palette.primary.fontColor,
    },
  },

  [`& .${classes.formWrapper}`]: {
    marginBottom: 13,
    "& .textfield": {
      border: "1px solid " + theme.palette.drawer.border,
      borderRadius: 13,
      background: theme.palette.drawer.textFieldBg,
      "& input": {
        padding: "7px 18px",
        height: 11,
      },
    },
  },

  [`& .${classes.label}`]: {
    color: theme.palette.primary.fontColor,
    fontWeight: 500,
    fontSize: 16,
    display: "block",
    marginBottom: 5,
  },

  [`& .${classes.submitButton}`]: {
    marginLeft: "auto",
    display: "block",
    marginTop: 13,
    "& span": {
      padding: 0,
    },
  },
}));

const CreateManufacturerForm = ({
  currentRecord,
  onClose = () => {},
  setSelectedId,
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const id = currentRecord?.manufacturerId;
  const defaultValues = {
    bannerImage: currentRecord?.bannerImage || "",
    name: currentRecord?.name || "",
    email: currentRecord?.email || "",
    shortName: currentRecord?.shortName || "",
    description: currentRecord?.description || "",
    country: currentRecord?.country || "",
    city: currentRecord?.city || "",
    manufacturerUUID: currentRecord?.manufacturerUUID || uuidv4(),
    contactAddress: currentRecord?.contactAddress || "",
    logoImage: currentRecord?.logoImage || "",
    nameAlternative: currentRecord?.nameAlternative || "",
    classification: currentRecord?.classification || "",
    street: currentRecord?.street || "",
    telephone: currentRecord?.telephone || "",
    vat: currentRecord?.vat || "",
    website: currentRecord?.website || "",
    zipcode: currentRecord?.zipcode || "",
    socialYoutubeLink: currentRecord?.socialYoutubeLink || "",
    socialLinkedLink: currentRecord?.socialLinkedLink || "",
    socialInstaLink: currentRecord?.socialInstaLink || "",
    socialFbLink: currentRecord?.socialFbLink || "",
    socialTwitterLink: currentRecord?.socialTwitterLink || "",
    manufacturerId: "",
    featuredManufacturer: currentRecord?.featuredManufacturer || false,
  };

  const addNewSchema = yup.object().shape({
    bannerImage: yup.string(),
    logoImage: yup.string(),
    name: yup.string().required("Required"),
    email: yup.string().email("Invalid email"),
    shortName: yup.string(),
    description: yup.string(),
    country: yup.string().required("Required"),
    city: yup.string(),
    contactAddress: yup.string(),
    nameAlternative: yup.string(),
    classification: yup.string(),
    street: yup.string(),
    telephone: yup.string(),
    vat: yup.string(),
    website: yup.string(),
    zipcode: yup.string(),
    manufacturerUUID: yup.string(),
    socialTwitterLink: yup.string(),
    socialFbLink: yup.string(),
    socialInstaLink: yup.string(),
    socialLinkedLink: yup.string(),
    socialYoutubeLink: yup.string(),
    featuredManufacturer: yup.bool(),
  });

  const {
    control,
    formState,
    handleSubmit,
    getValues,
    setError,
    setValue,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(addNewSchema),
  });
  const { isValid, dirtyFields, errors } = formState;

  const onResponse = (res) => {
    if (!res?.status) {
      dispatch(
        showMessage({
          message: `The manufacturer has been ${id ? "updated" : "created"}`,
        }),
      );
      dispatch(resetSelected());
      reset();
      // Resetting the id to force a fresh loading if there was an update
      setSelectedId(undefined);
      onClose();
    } else if (Number(status) === 400) {
      if (
        data &&
        data["database exception"]
          ?.toString()
          ?.toLowerCase()
          ?.indexOf("duplicate") > -1
      ) {
        dispatch(
          showMessage({ message: "Email already exists", variant: "error" }),
        );
      }
    } else if (res?.status == 424) {
      setError("country", {
        type: "manual",
        message: "Invalid country",
      });
      dispatch(showMessage({ message: "Invalid country", variant: "error" }));
    }
  };

  const onSubmit = async (model) => {
    if (Boolean(id)) {
      const res = await dispatch(update(id, model));
      onResponse(res);
    } else {
      const res = await dispatch(create(model));
      onResponse(res);
    }
  };

  const handleUpload = (e, field) => {
    if (e.target.files.length) {
      setLoading(true);
      handleUploadFile(e, true)
        .then((res) => {
          setValue(field, res.data);
        })
        .catch((err) => {
          alert(err?.message || "Error uploading file");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Root className={classes.wrapper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <label className={classes.label}>Manufacturer UUID*</label>
          <Controller
            name="manufacturerUUID"
            control={control}
            className="textfield"
            render={({ field }) => (
              <TextField
                id="create-manu-input"
                {...field}
                error={!!errors.manufacturerUUID}
                helperText={errors?.manufacturerUUID?.message}
                sx={{ width: "100%" }}
                placeholder="manufacturerUUID"
                disabled={true}
                inputProps={{ maxLength: 240 }}
                required
              />
            )}
          />
          <Grid item xs={12} md={12}>
            <div className={classes.formWrapper}>
              <label className={classes.label}>Name*</label>
              <Controller
                name="name"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <TextField
                    id="create-manu-input"
                    {...field}
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                    sx={{ width: "100%" }}
                    placeholder="Name"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className={classes.formWrapper}>
              <label className={classes.label}>Short Name</label>
              <Controller
                name="shortName"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <TextField
                    id="create-manu-input"
                    {...field}
                    error={!!errors.shortName}
                    helperText={errors?.shortName?.message}
                    placeholder="Short Name"
                    sx={{ width: "100%" }}
                  />
                )}
              />
            </div>
          </Grid>

          <label className={classes.label}>Name Alternative</label>
          <Controller
            name="nameAlternative"
            control={control}
            className="textfield"
            render={({ field }) => (
              <TextField
                id="create-manu-input"
                {...field}
                error={!!errors.nameAlternative}
                helperText={errors?.nameAlternative?.message}
                variant="outlined"
                placeholder="Name Alternative"
                inputProps={{ maxLength: 240 }}
                sx={{ width: "100%" }}
              />
            )}
          />
          <Grid item xs={12} md={12}>
            <div className={classes.formWrapper}>
              <label className={classes.label}>Telephone</label>
              <Controller
                name="telephone"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <TextField
                    id="create-manu-input"
                    {...field}
                    error={!!errors.telephone}
                    helperText={errors?.telephone?.message}
                    type="phone"
                    inputProps={{ maxLength: 15 }}
                    placeholder="telephone"
                    sx={{ width: "100%" }}
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={12} md={12}>
            <div className={classes.formWrapper}>
              <label className={classes.label}>Email</label>
              <Controller
                name="email"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <TextField
                    id="create-manu-input"
                    {...field}
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    variant="outlined"
                    placeholder="Email"
                    inputProps={{ maxLength: 40 }}
                    sx={{ width: "100%" }}
                  />
                )}
              />
            </div>
          </Grid>
          <Box sx={{ width: "100%" }}>
            <label className={classes.label}>Website</label>
            <Controller
              name="website"
              control={control}
              className="textfield"
              render={({ field }) => (
                <TextField
                  id="create-manu-input"
                  {...field}
                  error={!!errors.website}
                  helperText={errors?.website?.message}
                  sx={{ width: "100%" }}
                  placeholder="Website"
                  inputProps={{ maxLength: 240 }}
                />
              )}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <label className={classes.label}>Description</label>
            <Controller
              name="description"
              control={control}
              className="textfield"
              render={({ field }) => (
                <TextField
                  id="create-manu-input"
                  {...field}
                  multiline
                  error={!!errors.description}
                  helperText={`${field.value.length}/2000`}
                  sx={{ width: "100%" }}
                  placeholder="Description"
                  inputProps={{ maxLength: 2000 }}
                />
              )}
            />
          </Box>

          <label className={classes.label}>Facebook</label>
          <Controller
            name="socialFbLink"
            control={control}
            className="textfield"
            render={({ field }) => (
              <TextField
                id="create-manu-input"
                {...field}
                error={!!errors.socialFbLink}
                helperText={`${field.value.length}/2000`}
                sx={{ width: "100%" }}
                placeholder="Facebook Profile"
                inputProps={{ maxLength: 2000 }}
              />
            )}
          />
          <label className={classes.label}>Twitter</label>
          <Controller
            name="socialTwitterLink"
            control={control}
            className="textfield"
            render={({ field }) => (
              <TextField
                id="create-manu-input"
                {...field}
                error={!!errors.socialTwitterLink}
                helperText={`${field.value.length}/2000`}
                sx={{ width: "100%" }}
                placeholder="Twitter Profile"
                inputProps={{ maxLength: 2000 }}
              />
            )}
          />
          <label className={classes.label}>LinkedIn</label>
          <Controller
            name="socialLinkedLink"
            control={control}
            className="textfield"
            render={({ field }) => (
              <TextField
                id="create-manu-input"
                {...field}
                error={!!errors.socialLinkedLink}
                helperText={`${field.value.length}/2000`}
                sx={{ width: "100%" }}
                placeholder="Linkedin Profile"
                inputProps={{ maxLength: 2000 }}
              />
            )}
          />
          <label className={classes.label}>Instagram</label>
          <Controller
            name="socialInstaLink"
            control={control}
            className="textfield"
            render={({ field }) => (
              <TextField
                id="create-manu-input"
                {...field}
                error={!!errors.socialInstaLink}
                helperText={`${field.value.length}/2000`}
                sx={{ width: "100%" }}
                placeholder="Instagram Profile"
                inputProps={{ maxLength: 2000 }}
              />
            )}
          />
          <label className={classes.label}>Youtube</label>
          <Controller
            name="socialYoutubeLink"
            control={control}
            className="textfield"
            render={({ field }) => (
              <TextField
                id="create-manu-input"
                {...field}
                error={!!errors.socialYoutubeLink}
                helperText={`${field.value.length}/2000`}
                sx={{ width: "100%" }}
                placeholder="Youtube Link"
                inputProps={{ maxLength: 2000 }}
              />
            )}
          />
          <Box sx={{ width: "100%" }}>
            <label className={classes.label}>VAT</label>
            <Controller
              name="vat"
              control={control}
              className="textfield"
              render={({ field }) => (
                <TextField
                  id="create-manu-input"
                  {...field}
                  error={!!errors.vat}
                  helperText={errors?.vat?.message}
                  sx={{ width: "100%" }}
                  placeholder="VAT"
                  inputProps={{ maxLength: 240 }}
                />
              )}
            />
          </Box>
          <label className={classes.label}>Street</label>
          <Controller
            name="street"
            control={control}
            className="textfield"
            render={({ field }) => (
              <TextField
                id="create-manu-input"
                {...field}
                error={!!errors.street}
                helperText={errors?.street?.message}
                sx={{ width: "100%" }}
                placeholder="Street"
                inputProps={{ maxLength: 240 }}
              />
            )}
          />
          <Box sx={{ width: "100%" }}>
            <label className={classes.label}>Zipcode</label>
            <Controller
              name="zipcode"
              control={control}
              className="textfield"
              render={({ field }) => (
                <TextField
                  id="create-manu-input"
                  {...field}
                  error={!!errors.zipcode}
                  helperText={errors?.zipcode?.message}
                  sx={{ width: 200 }}
                  placeholder="Zipcode"
                  inputProps={{ maxLength: 240 }}
                />
              )}
            />
          </Box>
          <label className={classes.label}>City</label>
          <Controller
            name="city"
            control={control}
            className="textfield"
            render={({ field }) => (
              <TextField
                id="create-manu-input"
                {...field}
                error={!!errors.city}
                helperText={errors?.city?.message}
                sx={{ width: "100%" }}
                placeholder="City"
                inputProps={{ maxLength: 240 }}
              />
            )}
          />
          <label className={classes.label}>Country*</label>
          <Controller
            name="country"
            control={control}
            className="textfield"
            render={({ field }) => (
              <TextField
                id="create-manu-input"
                {...field}
                error={!!errors.country}
                helperText={errors?.country?.message}
                sx={{ width: "100%" }}
                placeholder="Country"
                inputProps={{ maxLength: 240 }}
              />
            )}
          />
          <label className={classes.label}>Featured Manufacturer</label>
          <Controller
            name="featuredManufacturer"
            control={control}
            className="textfield"
            render={({ field }) => (
              <Switch
                {...field}
                disabled
                checked={getValues("featuredManufacturer")}
                error={!!errors.featuredManufacturer}
              />
            )}
          />
          <Grid item xs={12} md={12}>
            <div className={classes.formWrapper}>
              <label className={classes.label}>Banner Image</label>
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  marginBottom: "50px",
                }}>
                <img src={getValues("bannerImage")} alt="" />
                <input
                  type={"file"}
                  onChange={(e) => handleUpload(e, "bannerImage")}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className={classes.formWrapper}>
              <label className={classes.label}>Logo</label>
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  marginBottom: "50px",
                }}>
                <img src={getValues("logoImage")} alt="" />
                <input
                  type={"file"}
                  onChange={(e) => handleUpload(e, "logoImage")}
                />
              </div>
            </div>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!isValid || loading}
          className={classes.submitButton}>
          {loading ? "Saving..." : "Save"}
        </Button>
      </form>
    </Root>
  );
};

export default CreateManufacturerForm;
