import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteMaterial,
  list,
  searchData,
} from "../../../store/CustomMaterial/customMaterial";
import { showMessage } from "../../../store/Revalu/notificationSlice";
import { DialogHeader } from "../Common/dialogHeader";
import ActionButtons from "./actionButtons";
import AddUUIDinCustomMaterial from "./addUUIDinCustom";
import EditCustomMaterial from "./editCustomMaterial";
import ViewDetails from "./viewDetails";

const CustomMaterial = () => {
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [selectedIdToView, setSelectedIdToView] = useState(null);
  const [selectedIdToEdit, setSelectedIdToEdit] = useState(null);
  const [sortBy, setSortBy] = useState({});
  const [selectIdToLinkUUID, setSelectIdToLinkUUID] = useState(null);

  const dispatch = useDispatch();
  const { data, loading, importLoading, importProcessStatus, searchValue } =
    useSelector(({ customMaterial }) => customMaterial.slice);
  const { totalElements } = data;

  useEffect(() => {
    searchValue ? setPageNo(0) : pageNo;
    dispatch(list({ pageNo, pageSize, searchValue, sortBy }));
  }, [pageNo, pageSize, searchValue, sortBy]);

  const handleSortBy = (e, direction) => {
    const fieldNames = {
      "Material Name": "materialName",
      "Material Status": "customMaterialStatus",
      "Upload Date": "createdDate",
      "Last Update": "lastModifiedDate",
    };

    const directions = {
      asc: "ASC",
      desc: "DESC",
    };
    setSortBy({
      fieldName: fieldNames[e],
      direction: directions[direction],
    });
  };

  const handleViewDetails = (id) => {
    setSelectedIdToView(id);
  };

  const handleCloseDetails = () => {
    setSelectedIdToView(null);
  };

  const handleCloseEdit = () => {
    setSelectedIdToEdit(null);
  };

  const handleSelectedIdEdit = (id) => {
    setSelectedIdToEdit(id);
  };

  const handlerDelete = (id) => {
    dispatch(deleteMaterial(id));
  };

  const handleClose = () => {
    setSelectIdToLinkUUID(null);
  };

  const handleSelectIdUUID = (id) => {
    setSelectIdToLinkUUID(id);
  };

  const headers = [
    {
      name: "Material Name",
      options: {
        sort: true,
      },
    },
    {
      name: "Material Status",
      options: {
        sort: true,
      },
    },
    {
      name: "Upload Date",
      options: {
        sort: true,
      },
    },
    {
      name: "Last Update",
      options: {
        sort: true,
      },
    },
    {
      options: {
        sort: false,
        customBodyRender: (value) => {
          return (
            <ActionButtons
              onEdit={() => {}}
              onViewDetails={handleViewDetails}
              rowData={value}
              handlerDelete={handlerDelete}
              handleSelectedIdEdit={handleSelectedIdEdit}
              handleSelectIdUUID={handleSelectIdUUID}
            />
          );
        },
      },
    },
  ];
  const getParsedData = () => {
    return data?.content?.map((m) => {
      // const res = dispatch(getEmail(m?.userId))
      return [
        m?.materialName,
        m.customMaterialStatus,
        new Date(m.createdDate).toLocaleString("en-US"),
        new Date(m.lastModifiedDate).toLocaleString("en-US"),
        m,
      ];
    });
  };
  const onResponse = (res) => {
    if (!res) {
      dispatch(showMessage({ message: "No response", variant: "error" }));
      return false;
    }
    const { status, statusText } = res;
    if (Number(status) === 400) {
      dispatch(
        showMessage({
          message: statusText || "An uknown error occurred",
          variant: "error",
        }),
      );
    } else if (typeof res === "string") {
      dispatch(
        showMessage({
          message: res,
        }),
      );
    }
  };

  const options = {
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    checkbox: false,
    selectableRowsHeader: false,
    selectableRows: "none",
    filterType: "checkbox",
    serverSide: true,
    rowsPerPageOptions: [10, 20, 50],
    onColumnSortChange: (col, direction) => handleSortBy(col, direction),
    onSearchClose: () => {
      dispatch(searchData({ search: "" }));
    },
    onSearchChange: (e) => {
      if (e === null) {
        dispatch(searchData({ search: "" }));
      } else {
        dispatch(searchData({ search: e }));
      }
    },
    onTableChange: (action, state) => {
      const { page, rowsPerPage } = state;
      switch (action) {
        case "changePage":
          setPageNo(page);
          break;
        case "changeRowsPerPage":
          setPageSize(rowsPerPage);
          break;
      }
    },
  };

  return (
    <div>
      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(0,0,0,0.3)",
            display: "flex",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            padding: "30px 20px",
            zIndex: 999,
            "& >div": {
              zIndex: 1000,
            },
          }}>
          <CircularProgress />
        </Box>
      )}

      <div>
        <MUIDataTable
          title="Custom Material"
          columns={headers}
          data={getParsedData()}
          options={{ ...options, count: data?.totalElements }}
          onPageChange={() => {}}
        />
      </div>
      {Boolean(selectedIdToView) && (
        <Dialog
          open={Boolean(selectedIdToView)}
          maxWidth="lg"
          fullWidth
          onClose={(event, reason) => {
            if (reason === "escapeKeyDown") {
              handleCloseDetails();
            }
          }}
          scroll="body">
          <DialogTitle>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between">
              <Box>User Details</Box>
              <Box textAlign="right">
                <IconButton onClick={handleCloseDetails} size="large">
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <ViewDetails onClose={handleCloseDetails} id={selectedIdToView} />
        </Dialog>
      )}

      {Boolean(selectedIdToEdit) && (
        <Dialog
          open={Boolean(selectedIdToEdit)}
          maxWidth="lg"
          fullWidth
          scroll="body">
          <DialogHeader onClose={handleCloseEdit} title={"Change status"} />
          <EditCustomMaterial onClose={handleCloseEdit} id={selectedIdToEdit} />
        </Dialog>
      )}

      {Boolean(selectIdToLinkUUID) && (
        <Dialog
          open={Boolean(selectIdToLinkUUID)}
          maxWidth="lg"
          fullWidth
          onClose={handleClose}
          scroll="body">
          <DialogHeader
            onClose={handleClose}
            title={"Add UUID to Custom Material"}
          />
          <AddUUIDinCustomMaterial
            option="user"
            onClose={handleClose}
            id={selectIdToLinkUUID}
            // refreshPage={refreshPage}
          />
        </Dialog>
      )}
    </div>
  );
};

export default CustomMaterial;
