import axios from "axios";
import { BASE_URL_V2 } from "../../config/config";
/* eslint-disable camelcase */

class MappedEPDService {
  list = (data) => {
    const {
      pageNo = 0,
      pageSize = 10,
      searchValue = "",
      sortEnum = "BLOCKED",
    } = data || {};
    return new Promise((resolve, reject) => {
      axios
        .get(
          BASE_URL_V2("epd") +
            "extracted/get-export-data?pageNo=" +
            pageNo +
            "&pageSize=" +
            pageSize +
            "&identifier=" +
            searchValue +
            "&sortEnum=" +
            sortEnum,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };
  changeBlockedStatus = (data) => {
    const { blocked, id } = data;
    return new Promise((resolve, reject) => {
      axios
        .post(
          BASE_URL_V2("epd") +
            "extracted/epd-data-status?dataSourceId=" +
            id +
            "&isBlocked=" +
            blocked,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };
  export = (params) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          BASE_URL_V2("epd") + "extracted/extractAndDownloadSelectedFields",
          params,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };
  startExportProcess = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(BASE_URL_V2("epd") + "epd-attribute-mapping/export-mapping-data")
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };
  getExportProcessStatus = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          BASE_URL_V2("epd") +
            "epd-attribute-mapping/export-mapping-data/status",
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };
  getById = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(BASE_URL_V2("epd") + "extracted/get-by-id?dataSourceId=" + id)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };
  deleteById = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          BASE_URL_V2("epd") + "extracted/delete-by-id?dataSourceId=" + id,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };
  update = (data) => {
    const { fieldName, dataSourceId, value } = data;
    const finalValue = encodeURIComponent(value);
    return new Promise((resolve, reject) => {
      axios
        .put(
          BASE_URL_V2("epd") +
            "extracted/update/updateExportDataByFieldName?fieldName=" +
            fieldName +
            "&dataSourceId=" +
            dataSourceId +
            "&value=" +
            finalValue,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  deleteKeyword = (data) => {
    const { fieldName, dataSourceId } = data;
    return new Promise((resolve, reject) => {
      axios
        .put(
          BASE_URL_V2("epd") +
            "extracted/delete/deleteExportDataByFieldName?dataSourceId=" +
            dataSourceId +
            "&fieldName=" +
            fieldName,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  qualityCheck = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          BASE_URL_V2("epd") +
            "qualityValidation/edp-quality-check?dataSourceId=" +
            id,
        )
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };
  statusUpdate = (data, id) => {
    const status = data.value.toUpperCase();
    return new Promise((resolve, reject) => {
      axios
        .put(
          BASE_URL_V2("epd") +
            "qualityValidation/manual-update-quality-status?dataSourceId=" +
            id +
            "&qualityStatusType=" +
            status,
        )
        .then((response) => {
          if (response) {
            resolve(response);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };
  getElements = () => {
    // const { pageNo = 0, pageSize = 100} = data || {}
    return new Promise((resolve, reject) => {
      axios
        .get(
          BASE_URL_V2("epd") +
            "categoryHierarchy/allPaginatedElements?pageNo=" +
            0 +
            "&pageSize=" +
            100,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };
  getBroaderCategories = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          BASE_URL_V2("epd") +
            "categoryHierarchy/getBroaderCategoryByElementId?elementId=" +
            id,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };
  getSubCategory = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          BASE_URL_V2("epd") +
            "categoryHierarchy/getSubCategoryByBroaderCategoryId?broaderCategoryId=" +
            id,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  getCategoryHierarchyID = (value) => {
    const data = {
      params: {
        broaderCategoryId: value.getBroaderCategoriesID,
        elementId: value.getElementsID,
        subCategoryId: value.getSubCategoriesID,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .get(
          BASE_URL_V2("epd") +
            "categoryHierarchy/getRelationByElementAndBroaderAndSubCategory",
          data,
        )
        .then((response) => {
          if (response) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  submitCategoryHierarchy = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          BASE_URL_V2("epd") +
            "extracted/update-epd-mapped-data-by-category-hierarchy?categoryHierarchyId=" +
            data.categoryHierarchyId +
            "&dataSourceId=" +
            data.dataSourceId,
        )
        .then((response) => {
          if (response) {
            resolve(response);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  uploadDocument = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          BASE_URL_V2("epd") + "extractedDataDocument/createMappedDocument",
          data,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  updateDocument = (id, data) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          BASE_URL_V2("epd") +
            "extractedDataDocument/updateMappedDocument?documentId=" +
            id,
          data,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  getMappedDocuments = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          BASE_URL_V2("epd") +
            "extractedDataDocument/getMappedDocuments?dataSourceId=" +
            id,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };
  getMappedReferencedDocuments = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          BASE_URL_V2("epd") +
            "referenceProjectDocument/getProjectDocument?dataSourceId=" +
            id,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };
  deleteMappedDocument = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          BASE_URL_V2("epd") +
            "extractedDataDocument/deleteById?documentId=" +
            id,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  deleteReferenceMappedDocument = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          BASE_URL_V2("epd") +
            "referenceProjectDocument/deleteById?documentId=" +
            id,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  createReferenceProjectDocument = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          BASE_URL_V2("epd") + "referenceProjectDocument/createProjectDocument",
          data,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  // getData = (id) => {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get(BASE_URL_V2("epd") + "categoryHierarchy/findById?id=" + id)
  //       .then((response) => {
  //         if (response.data) {
  //           resolve(response.data)
  //         } else {
  //           reject(response.error)
  //         }
  //       })
  //       .catch((error) => {
  //         const { response } = error
  //         reject(response)
  //       })
  //   })
  // }
}

const instance = new MappedEPDService();

export default instance;
